import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Action, StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { Relative } from "../domain/entities/Relative";
import { createRelativeThunk, deleteRelativeThunk, findRelativeByIdThunk, searchRelativesThunk, updateRelativeThunk, searchRelativesByInternThunk } from './RelativeThunk';

interface RelativeState {
    relativeAction: Action;
    relatives: Relative[];
    relativesByIntern: Relative[];
    relativeSelected?: Relative;
    status: StateStatus;
}

export const initialState: RelativeState = {
    relativeAction: 'none',
    relatives: [],
    relativesByIntern: [],
    relativeSelected: undefined,
    status: 'ready'
}

const relativeSlice = createSlice({
    name: 'relatives',
    initialState,
    reducers: {
        changeRelativeAction: (state: RelativeState, action: PayloadAction<Action>) => {
            state.relativeAction = action.payload;
        },
        selectRelative: (state: RelativeState, action: PayloadAction<Relative | undefined>) => {
            state.relativeSelected = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<RelativeState>) => {

        /*
            Create Relative
        */
        builder.addCase(createRelativeThunk.pending, (state: RelativeState) => {
            state.status = 'loading';
        }).addCase(createRelativeThunk.fulfilled, (state: RelativeState, action: PayloadAction<Relative>) => {
            state.relatives.unshift(action.payload);
            state.relativesByIntern.unshift(action.payload);
            state.status = 'ready';
        }).addCase(createRelativeThunk.rejected, (state: RelativeState) => {
            state.status = 'error';
        });

        /*
            Delete Relative
        */
        builder.addCase(deleteRelativeThunk.pending, (state: RelativeState) => {
            state.status = 'loading';
        }).addCase(deleteRelativeThunk.fulfilled, (state: RelativeState, action: PayloadAction<Relative>) => {
            state.status = 'ready';
            state.relatives = state.relatives.filter(relative => relative.id !== action.payload.id);
            if (state.relativeSelected?.id === action.payload.id) {
                state.relativeSelected = undefined
            }
            state.relativeAction = 'none';
        }).addCase(deleteRelativeThunk.rejected, (state: RelativeState) => {
            state.status = 'error';
        });

        /*
            Find Relative By Id
        */
       builder.addCase(findRelativeByIdThunk.pending, (state: RelativeState) => {
            state.status = 'loading';
       }).addCase(findRelativeByIdThunk.fulfilled, (state: RelativeState, action: PayloadAction<Relative>) => {
            state.relativeSelected = action.payload;
            state.status = 'ready';
       }).addCase(findRelativeByIdThunk.rejected, (state: RelativeState) => {
            state.status = 'error';
       });

       /*
            Search Relatives
       */
        builder.addCase(searchRelativesThunk.pending, (state: RelativeState) => {
            state.status = 'loading';
        }).addCase(searchRelativesThunk.fulfilled, (state: RelativeState, action: PayloadAction<Relative[]>) => {
            state.relatives = action.payload;
            state.status = 'ready';
        }).addCase(searchRelativesThunk.rejected, (state: RelativeState) => {
            state.status = 'error';
        });

        /*
            Search Relatives By Intern
       */
        builder.addCase(searchRelativesByInternThunk.pending, (state: RelativeState) => {
            state.status = 'loading';
        }).addCase(searchRelativesByInternThunk.fulfilled, (state: RelativeState, action: PayloadAction<Relative[]>) => {
            state.relativesByIntern = action.payload;
            state.status = 'ready';
        }).addCase(searchRelativesByInternThunk.rejected, (state: RelativeState) => {
            state.status = 'error';
        });

        /*
            Update Relative
        */
        builder.addCase(updateRelativeThunk.pending, (state: RelativeState) => {
            state.status = 'loading';
        }).addCase(updateRelativeThunk.fulfilled, (state: RelativeState, action: PayloadAction<Relative>) => {
            state.relatives = state.relatives.map(relative => relative.id === action.payload.id ? action.payload : relative);
            state.relativesByIntern = state.relativesByIntern.map(relative => relative.id === action.payload.id ? action.payload : relative);
            if (state.relativeSelected?.id == action.payload.id) {
                state.relativeSelected = action.payload;
            }
            state.status = 'ready';
        }).addCase(updateRelativeThunk.rejected, (state: RelativeState) => {
            state.status = 'error';
        });
    }
});

const { changeRelativeAction, selectRelative } = relativeSlice.actions;

const getRelativeAction = (state: RootState) => state.relative.relativeAction;
const getRelatives = (state: RootState) => state.relative.relatives;
const getRelativesByIntern = (state: RootState) => state.relative.relativesByIntern;
const getRelativeSelected = (state: RootState) => state.relative.relativeSelected;
const getStatus = (state: RootState) => state.relative.status;

export {
    changeRelativeAction,
    selectRelative,

    getRelativeAction,
    getRelatives,
    getRelativesByIntern,
    getRelativeSelected,
    getStatus
}

export default relativeSlice.reducer;