import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Action, StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { Menu } from "../domain/entities/Menu";
import {
  createMenuThunk,
  deleteMenuThunk,
  findMenuByIdThunk,
  searchMenusThunk,
  updateMenuThunk,
} from "./MenuThunk";
import { SearchResponse } from "../../commons/Types";
import { MenuFilter } from "./MenuDto";

interface MenuState {
  menuAction: Action;
  menus: Menu[];
  filter: MenuFilter;
  status: StateStatus;
  totalCount: number;

  menuSelected?: Menu;
}

export const initialState: MenuState = {
  menuAction: "none",
  menus: [],
  menuSelected: undefined,
  filter: {
    skip: 0,
    take: 10,
  },
  status: "ready",
  totalCount: 0,
};

const menuSlice = createSlice({
  name: "menus",
  initialState,
  reducers: {
    changeMenuAction: (state: MenuState, action: PayloadAction<Action>) => {
      state.menuAction = action.payload;
    },

    selectMenu: (state: MenuState, action: PayloadAction<Menu | undefined>) => {
      state.menuSelected = action.payload;
    },

    changeFilter: (state: MenuState, action: PayloadAction<MenuFilter>) => {
      state.filter = action.payload;
    },

    changeFilterSkip: (state: MenuState, action: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        skip: action.payload,
      };
    },

    changeFilterTake: (state: MenuState, action: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        skip: 0,
        take: action.payload,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<MenuState>) => {
    const updateMenu = (state: MenuState, action: PayloadAction<Menu>) => {
      state.menus = state.menus.map((menu) =>
        menu.id === action.payload.id ? action.payload : menu
      );
      if (state.menuSelected?.id == action.payload.id) {
        state.menuSelected = action.payload;
      }
      state.status = "ready";
    };

    /*
            Create Menu
        */
    builder
      .addCase(createMenuThunk.pending, (state: MenuState) => {
        state.status = "loading";
      })
      .addCase(
        createMenuThunk.fulfilled,
        (state: MenuState, action: PayloadAction<Menu>) => {
          state.menus.unshift(action.payload);
          state.menuSelected = undefined;
          state.status = "ready";
        }
      )
      .addCase(createMenuThunk.rejected, (state: MenuState) => {
        state.status = "error";
      });

    /*
            Delete Menu
        */
    builder
      .addCase(deleteMenuThunk.pending, (state: MenuState) => {
        state.status = "loading";
      })
      .addCase(
        deleteMenuThunk.fulfilled,
        (state: MenuState, action: PayloadAction<Menu>) => {
          state.status = "ready";
          state.menus = state.menus.filter(
            (menu) => menu.id !== action.payload.id
          );
          if (state.menuSelected?.id === action.payload.id) {
            state.menuSelected = undefined;
          }
          state.menuAction = "none";
        }
      )
      .addCase(deleteMenuThunk.rejected, (state: MenuState) => {
        state.status = "error";
      });

    /*
            Find Menu By Id
        */
    builder
      .addCase(findMenuByIdThunk.pending, (state: MenuState) => {
        state.status = "loading";
      })
      .addCase(
        findMenuByIdThunk.fulfilled,
        (state: MenuState, action: PayloadAction<Menu>) => {
          state.menuSelected = action.payload;
          state.status = "ready";
        }
      )
      .addCase(findMenuByIdThunk.rejected, (state: MenuState) => {
        state.status = "error";
      });

    /*
            Search Menus
       */
    builder
      .addCase(searchMenusThunk.pending, (state: MenuState) => {
        state.status = "loading";
      })
      .addCase(
        searchMenusThunk.fulfilled,
        (state: MenuState, action: PayloadAction<SearchResponse<Menu>>) => {
          state.menus = action.payload.data;
          state.totalCount = action.payload.totalCount;
        }
      )
      .addCase(searchMenusThunk.rejected, (state: MenuState) => {
        state.status = "error";
      });

    /*
            Update Menu
        */
    builder
      .addCase(updateMenuThunk.pending, (state: MenuState) => {
        state.status = "loading";
      })
      .addCase(
        updateMenuThunk.fulfilled,
        (state: MenuState, action: PayloadAction<Menu>) => {
          updateMenu(state, action);
        }
      )
      .addCase(updateMenuThunk.rejected, (state: MenuState) => {
        state.status = "error";
      });
  },
});

const { changeFilterSkip, changeMenuAction, selectMenu } = menuSlice.actions;

const getFilter = (state: RootState) => state.menu.filter;
const getMenuAction = (state: RootState) => state.menu.menuAction;
const getMenus = (state: RootState) => state.menu.menus;
const getMenuSelected = (state: RootState) => state.menu.menuSelected;
const getStatus = (state: RootState) => state.menu.status;
const getTotalCount = (state: RootState) => state.menu.totalCount;

export {
  changeFilterSkip,
  changeMenuAction,
  selectMenu,
  getFilter,
  getMenuAction,
  getMenus,
  getMenuSelected,
  getStatus,
  getTotalCount,
};

export default menuSlice.reducer;
