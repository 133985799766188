import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Action, StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { Employee } from "../domain/entities/Employee";
import {
  createEmployeeThunk,
  deleteEmployeeThunk,
  findEmployeeByIdThunk,
  searchEmployeesThunk,
  updateEmployeeThunk,
  addPayrollToEmployeeThunk,
  removePayrollToEmployeeThunk,
} from "./EmployeeThunk";
import { EmployeeFilter } from "./EmployeeDto";
import { SearchResponse } from "../../commons/Types";

interface EmployeeState {
  employeeAction: Action;
  employees: Employee[];
  employeeSelected?: Employee;
  filter: EmployeeFilter;
  status: StateStatus;
  totalCount: number;
}

export const initialState: EmployeeState = {
  employeeAction: "none",
  employees: [],
  employeeSelected: undefined,
  filter: {
    searchText: "",
    skip: 0,
    status: 'active',
    take: 10,
  },
  status: "ready",
  totalCount: 0,
};

const employeeSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    changeEmployeeAction: (
      state: EmployeeState,
      action: PayloadAction<Action>
    ) => {
      state.employeeAction = action.payload;
    },

    changeFilterText: (state: EmployeeState, action: PayloadAction<string>) => {
      state.filter = {
        ...state.filter,
        skip: 0,
        searchText: action.payload,
      };
    },

    changeFilterStatus: (
      state: EmployeeState,
      action: PayloadAction<string | null>
    ) => {
      state.filter = {
        ...state.filter,
        status: action.payload,
      };
    },

    changeFilterSkip: (state: EmployeeState, action: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        skip: action.payload,
      };
    },

    changeFilterTake: (state: EmployeeState, action: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        skip: 0,
        take: action.payload,
      };
    },

    selectEmployee: (
      state: EmployeeState,
      action: PayloadAction<Employee | undefined>
    ) => {
      state.employeeSelected = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<EmployeeState>) => {
    const updateEmployee = (
      state: EmployeeState,
      action: PayloadAction<Employee>
    ) => {
      state.employees = state.employees.map((employee) =>
        employee.id === action.payload.id ? action.payload : employee
      );
      if (state.employeeSelected?.id == action.payload.id) {
        state.employeeSelected = action.payload;
      }
      state.status = "ready";
    };

    /*
            Add Payroll To Employee
        */
    builder
      .addCase(addPayrollToEmployeeThunk.pending, (state: EmployeeState) => {
        state.status = "loading";
      })
      .addCase(
        addPayrollToEmployeeThunk.fulfilled,
        (state: EmployeeState, action: PayloadAction<Employee>) => {
          updateEmployee(state, action);
        }
      )
      .addCase(addPayrollToEmployeeThunk.rejected, (state: EmployeeState) => {
        state.status = "error";
      });

    /*
            Create Employee
        */
    builder
      .addCase(createEmployeeThunk.pending, (state: EmployeeState) => {
        state.status = "loading";
      })
      .addCase(
        createEmployeeThunk.fulfilled,
        (state: EmployeeState, action: PayloadAction<Employee>) => {
          state.employees.unshift(action.payload);
          state.employeeSelected = undefined;
          state.status = "ready";
        }
      )
      .addCase(createEmployeeThunk.rejected, (state: EmployeeState) => {
        state.status = "error";
      });

    /*
            Delete Employee
        */
    builder
      .addCase(deleteEmployeeThunk.pending, (state: EmployeeState) => {
        state.status = "loading";
      })
      .addCase(
        deleteEmployeeThunk.fulfilled,
        (state: EmployeeState, action: PayloadAction<Employee>) => {
          state.status = "ready";
          state.employees = state.employees.filter(
            (employee) => employee.id !== action.payload.id
          );
          if (state.employeeSelected?.id === action.payload.id) {
            state.employeeSelected = undefined;
          }
          state.employeeAction = "none";
        }
      )
      .addCase(deleteEmployeeThunk.rejected, (state: EmployeeState) => {
        state.status = "error";
      });

    /*
            Find Employee By Id
        */
    builder
      .addCase(findEmployeeByIdThunk.pending, (state: EmployeeState) => {
        state.status = "loading";
      })
      .addCase(
        findEmployeeByIdThunk.fulfilled,
        (state: EmployeeState, action: PayloadAction<Employee>) => {
          state.employeeSelected = action.payload;
          state.status = "ready";
        }
      )
      .addCase(findEmployeeByIdThunk.rejected, (state: EmployeeState) => {
        state.status = "error";
      });

    /*
            Add Payroll To Employee
        */
    builder
      .addCase(removePayrollToEmployeeThunk.pending, (state: EmployeeState) => {
        state.status = "loading";
      })
      .addCase(
        removePayrollToEmployeeThunk.fulfilled,
        (state: EmployeeState, action: PayloadAction<Employee>) => {
          updateEmployee(state, action);
        }
      )
      .addCase(
        removePayrollToEmployeeThunk.rejected,
        (state: EmployeeState) => {
          state.status = "error";
        }
      );

    /*
            Search Employees
       */
    builder
      .addCase(searchEmployeesThunk.pending, (state: EmployeeState) => {
        state.status = "loading";
      })
      .addCase(
        searchEmployeesThunk.fulfilled,
        (
          state: EmployeeState,
          action: PayloadAction<SearchResponse<Employee>>
        ) => {
          state.employees = action.payload.data;
          state.totalCount = action.payload.totalCount;
          state.status = "ready";
        }
      )
      .addCase(searchEmployeesThunk.rejected, (state: EmployeeState) => {
        state.status = "error";
      });

    /*
            Update Employee
        */
    builder
      .addCase(updateEmployeeThunk.pending, (state: EmployeeState) => {
        state.status = "loading";
      })
      .addCase(
        updateEmployeeThunk.fulfilled,
        (state: EmployeeState, action: PayloadAction<Employee>) => {
          updateEmployee(state, action);
        }
      )
      .addCase(updateEmployeeThunk.rejected, (state: EmployeeState) => {
        state.status = "error";
      });
  },
});

const {
  changeEmployeeAction,
  changeFilterSkip,
  changeFilterStatus,
  changeFilterTake,
  changeFilterText,
  selectEmployee,
} = employeeSlice.actions;

const getEmployeeAction = (state: RootState) => state.employee.employeeAction;
const getEmployeeFilter = (state: RootState) => state.employee.filter;
const getEmployees = (state: RootState) => state.employee.employees;
const getEmployeeSelected = (state: RootState) =>
  state.employee.employeeSelected;
const getStatus = (state: RootState) => state.employee.status;
const getTotalCount = (state: RootState) => state.employee.totalCount;

export {
  changeEmployeeAction,
  changeFilterSkip,
  changeFilterStatus,
  changeFilterTake,
  changeFilterText,
  selectEmployee,
  getEmployeeAction,
  getEmployeeFilter,
  getEmployees,
  getEmployeeSelected,
  getStatus,
  getTotalCount,
};

export default employeeSlice.reducer;
