import { NotArrayError } from '../../../../app/errors/NotArrayError';
export class Turn {

    constructor(
        readonly id: string,
        readonly endDate: Date,
        readonly initDate: Date,
    ) {}

    public static fromJson(json: any): Turn {
        return new Turn(
            json['id'],
            json['endDate'],
            json['initDate'],
        );
    }

    public static fromJsonArray(jsonArray: any): Turn[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }

}