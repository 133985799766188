import { lazy } from "react";
import { Navigate, Route, Routes } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';
import AdminLayout from "src/app/layouts/ExtendedSidebarLayout";
import { ProtectedRoute } from "./ProtectedRoute";
import { NavigationRoutes } from "./NavigationRoutes";
import { Loader } from "src/app/components/SuspenseLoader";
import { User } from "src/features/commons/Entities";

const ActivitiesPage = Loader(lazy(() => import('src/app/pages/activities')));
const ActivityDetailsPage = Loader(lazy(() => import('src/app/pages/activity_details')));
const InternDetailsPage = Loader(lazy(() => import('src/app/pages/intern_details')));
const InternsPage = Loader(lazy(() => import('src/app/pages/interns')));
const MenusPage = Loader(lazy(() => import('src/app/pages/menus')));
const LoginPage = Loader(lazy(() => import('src/app/pages/login')));
const PayrollsPage = Loader(lazy(() => import('src/app/pages/payrolls')));
const RecoveryPasswordPage = Loader(lazy(() => import('src/app/pages/recovery_password')));
const UserProfilePage = Loader(lazy(() => import('src/app/pages/user_profile')));
const WorkersPage = Loader(lazy(() => import('src/app/pages/workers')));
const LogsPage = Loader(lazy(() => import('src/app/pages/log')));

const redirectToHome = (user?: User): any => {
    if (!user || user?.status !== 'active') {
        return NavigationRoutes.Login;
    }

    if (user.role === 'admin') {
        return NavigationRoutes.Activities;
    } else if (user.role === 'worker') {
        return NavigationRoutes.Workers;
    } else if (user.role === 'relative') {
        return NavigationRoutes.Login;
    }
}

const AppNavigation = () => {
    const { user } = useAuth();

    const publicRoutes = [
        <Route
            key={NavigationRoutes.Login}
            path={NavigationRoutes.Login}
            element={
                <ProtectedRoute
                    isAllowed={user === null || user.status === 'inactive'}
                    redirectPath={redirectToHome(user)}
                >
                    <LoginPage />
                </ProtectedRoute>
            }
        />,
        <Route
            key={NavigationRoutes.RecoveryPassword}
            path={NavigationRoutes.RecoveryPassword}
            element={
                <ProtectedRoute
                    isAllowed={user === null}
                    redirectPath={redirectToHome(user)}
                >
                    <RecoveryPasswordPage />
                </ProtectedRoute>
            }
        />,
    ];

    const adminRoutes = [
        <Route key={'loginUserRoutes'} element={user ? <AdminLayout /> : undefined}>

            <Route
                key={NavigationRoutes.Activities}
                path={NavigationRoutes.Activities}
                element={
                    <ProtectedRoute
                        isAllowed={user?.status === 'active'}
                        redirectPath={redirectToHome(user)}
                    >
                        <ActivitiesPage />
                    </ProtectedRoute>}>
            </Route>

            <Route
                key={NavigationRoutes.ActivityDetails}
                path={NavigationRoutes.ActivityDetails}
                element={
                    <ProtectedRoute
                        isAllowed={user?.status === 'active'}
                        redirectPath={redirectToHome(user)}
                    >
                        <ActivityDetailsPage />
                    </ProtectedRoute>}>
            </Route>

            <Route
                key={NavigationRoutes.InternDetails}
                path={NavigationRoutes.InternDetails}
                element={
                    <ProtectedRoute
                        isAllowed={user?.status === 'active'}
                        redirectPath={redirectToHome(user)}
                    >
                        <InternDetailsPage />
                    </ProtectedRoute>}>
            </Route>

            <Route
                key={NavigationRoutes.Interns}
                path={NavigationRoutes.Interns}
                element={
                    <ProtectedRoute
                        isAllowed={user?.status === 'active'}
                        redirectPath={redirectToHome(user)}
                    >
                        <InternsPage />
                    </ProtectedRoute>}>
            </Route>

            <Route
                key={NavigationRoutes.Menus}
                path={NavigationRoutes.Menus}
                element={
                    <ProtectedRoute
                        isAllowed={user !== null}
                        redirectPath={NavigationRoutes.Login}
                    >
                        <MenusPage />
                    </ProtectedRoute>
                }
            />

            <Route
                key={NavigationRoutes.Payrolls}
                path={NavigationRoutes.Payrolls}
                element={
                    <ProtectedRoute
                        isAllowed={user !== null}
                        redirectPath={NavigationRoutes.Login}
                    >
                        <PayrollsPage />
                    </ProtectedRoute>
                }
            />

            <Route
                key={NavigationRoutes.Profile}
                path={NavigationRoutes.Profile}
                element={
                    <ProtectedRoute
                        isAllowed={user !== null}
                        redirectPath={NavigationRoutes.Login}
                    >
                        <UserProfilePage />
                    </ProtectedRoute>}
            />
            <Route
                key={NavigationRoutes.Workers}
                path={NavigationRoutes.Workers}
                element={
                    <ProtectedRoute
                        isAllowed={user !== null}
                        redirectPath={NavigationRoutes.Login}
                    >
                        <WorkersPage />
                    </ProtectedRoute>
                }
            />

            <Route
                key={NavigationRoutes.Logs}
                path={NavigationRoutes.Logs}
                element={
                    <ProtectedRoute
                        isAllowed={user !== null}
                        redirectPath={NavigationRoutes.Login}
                    >
                        <LogsPage />
                    </ProtectedRoute>
                }
            />
        </Route>
    ];

    const homePath = (
        <Route path="/" element={<Navigate to={redirectToHome(user)} replace />} />
    );

    const unknownPath = (
        <Route path="*" element={<Navigate to={redirectToHome(user)} replace />} />

    );

    return (
        <Routes>
            {homePath}
            {publicRoutes}
            {adminRoutes}
            {unknownPath}
        </Routes >
    );
}

export default AppNavigation;