import axios, { AxiosInstance } from 'axios';
import { getAuth } from 'firebase/auth';

const _axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

_axiosInstance.interceptors.response.use(
    response => response,
    async error => {
        const status = error.response ? error.response.status : null;
        if (status === 401) {
            const auth = getAuth();
            if (auth.currentUser) {
                const newToken = await auth.currentUser!.getIdToken(true);
                error.config.headers['Authorization'] = 'Bearer ' + newToken;
                localStorage.setItem('token', newToken);
                return _axiosInstance.request(error.config);
            }
        }
    }
);

async function deleteRequest(route: string, params?: any): Promise<any> {
    return _axiosInstance.delete(route, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            author: localStorage.getItem("userId"),
            center_id: localStorage.getItem('center_id')
        }
    }).then(result => result.data);
}

async function getRequest(route: string, params?: any, headers?: any, totalCount?: boolean): Promise<any> {
    return _axiosInstance
        .get(`${route}${params ? objectToQueryParams(params) : ''}`, {
            headers: {
                ...headers,
                'Access-Control-Allow-Origin': '*',
                center_id: localStorage.getItem('center_id')
            }
        })
        .then(result => {
            if (totalCount) {
                return {
                    data: result.data,
                    totalCount: parseInt(result.headers['x-total-count'] ?? '0')
                }
            } else {
                return result.data;
            }
        })
}

async function patchRequest(route: string, body: any): Promise<any> {
    return _axiosInstance.patch(route, body, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            author: localStorage.getItem("userId"),
            center_id: localStorage.getItem('center_id')
        }
    }).then(result => result.data);
}

async function postRequest(route: string, body: any, params?: any): Promise<any> {
    return _axiosInstance.post(`${route}${params ? objectToQueryParams(params) : ''}`, body, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            author: localStorage.getItem("userId"),
            center_id: localStorage.getItem('center_id')
        }
    }).then(result => result.data);
}

async function putRequest(route: string, body: any): Promise<any> {
    return _axiosInstance.put(route, body, {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            center_id: localStorage.getItem('center_id')
        }
    }).then(result => result.data);
}

function objectToQueryParams(obj: any): string {
    obj = JSON.parse(JSON.stringify(obj));
    let str = [];
    for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    }
    return str.length > 0 ? `?${str.join("&")}` : '';
}


export {
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
    putRequest,
    objectToQueryParams
}
