import { NotArrayError } from 'src/app/errors/NotArrayError';

export class DescriptionBlock {
    constructor(
        readonly id: string,
        readonly centerId: string,
        readonly description: string,
        readonly photoUrl: string,
        readonly title: string,
    ) { }

    public static fromJson(json: any): DescriptionBlock {
        return new DescriptionBlock(
            json['id'],
            json['centerId'],
            json['description'],
            json['photoUrl'],
            json['title'],
        );
    }

    public static fromJsonArray(jsonArray: any): DescriptionBlock[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}