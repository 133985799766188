import { createAsyncThunk } from '@reduxjs/toolkit';
import { SearchResponse } from 'src/features/commons/Types';
import { Activity } from '../domain/entities/Activity';
import { CreateActivityDto, UpdateActivityDto, ActivityFilter, CreateGroupDto, AddTurnDto, RemoveGroupDto, RemoveTurnDto, UpdateGroupDto, UpdateTurnDto } from './ActivityDto';
import { ActivityService } from './ActivityService';

export const addTurnThunk = createAsyncThunk(
    'activity/create_turn',
    async (addTurn: AddTurnDto): Promise<Activity> =>
        ActivityService.addTurnAsync(addTurn)
);

export const createActivityThunk = createAsyncThunk(
    'activity/create',
    async (createActivity: CreateActivityDto): Promise<Activity> =>
        ActivityService.createActivityAsync(createActivity)
);

export const createGroupThunk = createAsyncThunk(
    'activity/create_group',
    async (createGroup: CreateGroupDto): Promise<Activity> =>
        ActivityService.createGroupAsync(createGroup)
);

export const deleteActivityThunk = createAsyncThunk(
    'activity/delete',
    async (activityId: string) => ActivityService.deleteActivityAsync(activityId)
);

export const deleteGroupThunk = createAsyncThunk(
    'activity/delete_group',
    async (removeGroup: RemoveGroupDto) => ActivityService.deleteGroupAsync(removeGroup)
);

export const deleteTurnThunk = createAsyncThunk(
    'activity/delete_turn',
    async (removeTurn: RemoveTurnDto) => ActivityService.deleteTurnAsync(removeTurn)
);

export const findActivityByIdThunk = createAsyncThunk(
    'activity/find_by_id',
    async (activityId: string): Promise<Activity> => ActivityService.findActivityByIdAsync(activityId)
);

export const searchActivitiesThunk = createAsyncThunk(
    'activity/search',
    async (filters: ActivityFilter): Promise<SearchResponse<Activity>> => ActivityService.searchActivitiesAsync(filters)
);

export const updateActivityThunk = createAsyncThunk(
    'activity/update',
    async (updateActivity: UpdateActivityDto): Promise<Activity> =>
        ActivityService.updateActivityAsync(updateActivity)
);

export const updateGroupThunk = createAsyncThunk(
    'activity/update_group',
    async (updateGroup: UpdateGroupDto): Promise<Activity> =>
        ActivityService.updateGroupAsync(updateGroup)
);

export const updateTurnThunk = createAsyncThunk(
    'activity/update_Turn',
    async (updateTurn: UpdateTurnDto): Promise<Activity> =>
        ActivityService.updateTurnAsync(updateTurn)
);