import { createAsyncThunk } from '@reduxjs/toolkit';
import { Relative } from '../domain/entities/Relative';
import { CreateRelativeDto, UpdateRelativeDto, RelativeFilter } from './RelativeDto';
import { RelativeService } from './RelativeService';

export const createRelativeThunk = createAsyncThunk(
    'relative/create',
    async (createRelative: CreateRelativeDto): Promise<Relative> =>
        RelativeService.createRelativeAsync(createRelative)
);

export const deleteRelativeThunk = createAsyncThunk(
    'relative/delete',
    async (relativeId: string) => RelativeService.deleteRelativeAsync(relativeId)
);

export const searchRelativesThunk = createAsyncThunk(
    'relative/search',
    async (filters: RelativeFilter): Promise<Relative[]> => RelativeService.searchRelativesAsync(filters)
);

export const searchRelativesByInternThunk = createAsyncThunk(
    'relative/search_by_intern',
    async (internId: string): Promise<Relative[]> => RelativeService.searchRelativesByInternIdAsync(internId)
);

export const updateRelativeThunk = createAsyncThunk(
    'relative/update',
    async (updateRelative: UpdateRelativeDto): Promise<Relative> =>
        RelativeService.updateRelativeAsync(updateRelative)
);

export const findRelativeByIdThunk = createAsyncThunk(
    'relative/find_by_id',
    async (relativeId: string): Promise<Relative> => RelativeService.findRelativeByIdAsync(relativeId)
);