import { NotArrayError } from '../../../../app/errors/NotArrayError';
export class Menu {
    constructor(
        readonly id: string,
        readonly createdAt: Date,
        public name: string,
        public url: string,

        public notes?: string,
    ) {}

    public static fromJson(json: any): Menu {
        return new Menu(
            json['id'],
            json['createdAt'],
            json['name'],
            json['url'],
            json['notes'],
        );
    }

    static fromJsonArray(jsonArray: any): Menu[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}