export enum NavigationRoutes {
    Activities = '/activities',
    ActivityDetails = "/activities/:activity_id/details",
    InternDetails = '/interns/:intern_id/details',
    Interns = '/interns',
    Login = '/login',
    Menus = '/menus',
    Payrolls = '/workers/:worker_id/payrolls',
    Profile = '/profile/:user_id',
    RecoveryPassword = '/recovery_password',
    Workers = '/workers',
    Logs = '/logs'
}