import { createAsyncThunk } from '@reduxjs/toolkit';
import { Intern } from '../domain/entities/Intern';
import { CreateInternDto, UpdateInternDto, InternFilter, AddDrugToInternDto, AddIncidentToInternDto, AddMedicalReportToInternDto, RemoveDrugFromInternDto, RemoveIncidentFromInternDto, RemoveMedicalReportFromInternDto } from './InternDto';
import { InternService } from './InternService';
import { SearchResponse } from '../../commons/Types';

export const addDrugToInternThunk = createAsyncThunk(
    'intern/add_drug',
    async (addDrugToIntern: AddDrugToInternDto): Promise<Intern> =>
        InternService.addDrugToInternAsync(addDrugToIntern)
);

export const addIncidentToInternThunk = createAsyncThunk(
    'intern/add_incident',
    async (addIncidentToIntern: AddIncidentToInternDto): Promise<Intern> =>
        InternService.addIncidentToInternAsync(addIncidentToIntern)
);

export const addMedicalReportToInternThunk = createAsyncThunk(
    'intern/add_medical_report',
    async (addMedicalReportToIntern: AddMedicalReportToInternDto): Promise<Intern> =>
        InternService.addMedicalReportToInternAsync(addMedicalReportToIntern)
);

export const createInternThunk = createAsyncThunk(
    'intern/create',
    async (createIntern: CreateInternDto): Promise<Intern> =>
        InternService.createInternAsync(createIntern)
);

export const deleteInternThunk = createAsyncThunk(
    'intern/delete',
    async (internId: string) => InternService.deleteInternAsync(internId)
);

export const findInternByIdThunk = createAsyncThunk(
    'intern/find_by_id',
    async (internId: string): Promise<Intern> => InternService.findInternByIdAsync(internId)
);

export const searchInternsThunk = createAsyncThunk(
    'intern/search',
    async (filters: InternFilter): Promise<SearchResponse<Intern>> => InternService.searchInternsAsync(filters)
);

export const updateInternThunk = createAsyncThunk(
    'intern/update',
    async (updateIntern: UpdateInternDto): Promise<Intern> =>
        InternService.updateInternAsync(updateIntern)
);

export const removeDrugFromInternThunk = createAsyncThunk(
    'intern/remove_drug',
    async (removeDrugFromIntern: RemoveDrugFromInternDto): Promise<Intern> =>
        InternService.removeDrugFromInternAsync(removeDrugFromIntern)
);

export const removeIncidentFromInternThunk = createAsyncThunk(
    'intern/remove_incident',
    async (removeIncidentFromIntern: RemoveIncidentFromInternDto): Promise<Intern> =>
        InternService.removeIncidentFromInternAsync(removeIncidentFromIntern)
);

export const removeMedicalReportFromInternThunk = createAsyncThunk(
    'intern/remove_medical_report',
    async (removeMedicalReportFromIntern: RemoveMedicalReportFromInternDto): Promise<Intern> =>
        InternService.removeMedicalReportFromInternAsync(removeMedicalReportFromIntern)
);

