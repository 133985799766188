import { NotArrayError } from "src/app/errors/NotArrayError";

export class Incident {
    constructor(
        readonly id: string,
        readonly date: Date,
        readonly description: string
    ) {}

    public static fromJson(json: any): Incident {
        return new Incident(
            json['id'],
            new Date(json['date']),
            json['description'],
        );
    }

    public static fromJsonArray(jsonArray: any): Incident[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}