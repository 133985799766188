import { NotArrayError } from "src/app/errors/NotArrayError";

export class Address {
    constructor(
        readonly id: string,
        readonly city: string,
        readonly createdAt: Date,
        readonly postcode: string,
        readonly province: string,
        readonly street: string,
        readonly lat?: number,
        readonly lng?: number,
        readonly number?: string
    ) { }

    public static fromJson(json: any): Address {
        return new Address(
            json['id'],
            json['city'],
            json['createdAt'],
            json['lat'],
            json['lng'],
            json['postcode'],
            json['province'],
            json['street'],
            json['number'],
        );
    }

    public static fromJsonArray(jsonArray: any): Address[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}