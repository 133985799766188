import { createAsyncThunk } from '@reduxjs/toolkit';
import { Center } from '../domain/entities/Center';
import { CreateCenterDto, UpdateCenterDto, CenterFilter } from './CenterDto';
import { CenterService } from './CenterService';

export const createCenterThunk = createAsyncThunk(
    'center/create',
    async (createCenter: CreateCenterDto): Promise<Center> =>
        CenterService.createCenterAsync(createCenter)
);

export const deleteCenterThunk = createAsyncThunk(
    'center/delete',
    async (centerId: string) => CenterService.deleteCenterAsync(centerId)
);

export const searchCentersThunk = createAsyncThunk(
    'center/search',
    async (filters: CenterFilter): Promise<Center[]> => CenterService.searchCentersAsync(filters)
);

export const updateCenterThunk = createAsyncThunk(
    'center/update',
    async (updateCenter: UpdateCenterDto): Promise<Center> =>
        CenterService.updateCenterAsync(updateCenter)
);

export const findCenterByIdThunk = createAsyncThunk(
    'center/find_by_id',
    async (centerId: string): Promise<Center> => CenterService.findCenterByIdAsync(centerId)
);

export const findCenterBySlugThunk = createAsyncThunk(
    'center/find_by_slug',
    async (slug: string): Promise<Center> => CenterService.findCenterBySlugAsync(slug)
);