import { NotArrayError } from "src/app/errors/NotArrayError";

export class MedicalReport {
    constructor(
        readonly id: string,
        readonly title: string,

        readonly notes?: string,
        readonly url?: string
    ) {}

    public static fromJson(json: any): MedicalReport {
        return new MedicalReport(
            json['id'],
            json['title'],
            json['notes'],
            json['url'],
        );
    }

    public static fromJsonArray(jsonArray: any): MedicalReport[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}