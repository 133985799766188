import { Turn } from './Turn';
import { NotArrayError } from '../../../../app/errors/NotArrayError';
import { Intern } from 'src/features/intern/domain/entities/Intern';

export class Group {

    constructor(
        readonly id: string,
        readonly activityId: string,
        public interns: Intern[],
        public turns: Turn[],
        public name: string,
        public notes?: string
    ) { }

    public static fromJson(json: any): Group {
        return new Group(
            json['id'],
            json['activityId'],
            Intern.fromJsonArray(json['interns']),
            Turn.fromJsonArray(json['turns']),
            json['name'],
            json['notes'],
        );
    }

    public static fromJsonArray(jsonArray: any): Group[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}