import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import firebase from 'src/config/firebase';
import PropTypes from 'prop-types';
import { getAuth, updateEmail } from "firebase/auth";
import { User, LoginEntity } from 'src/features/commons/Entities';
import { EmployeeService } from 'src/features/employee/data/EmployeeService';

export type ChangeEmailResult = 'success' | 'error' | 'login_required' | 'bad_password';

interface AuthState {
  isInitialized: boolean;
  isLoading: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'Firebase',
  changeEmail: (newEmail: string, oldEmail: string, password?: string) => Promise<any>;
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<any>;
  getCurrentUser: () => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  recoveryPassword: (email: string) => Promise<void>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type ChangeLoadingAction = {
  type: 'LOADING_CHANGE';
  payload: {
    isLoading: boolean;
  }
}

type Action = AuthStateChangedAction | ChangeLoadingAction;

const initialAuthState: AuthState = {
  isLoading: false,
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const reducer = (state: AuthState, action: Action): AuthState => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Firebase',
  changeEmail: () => Promise.resolve(),
  createUserWithEmailAndPassword: () => Promise.resolve(),
  getCurrentUser: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  recoveryPassword: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user !== null) {
          user.getIdToken().then((authToken) => {
            localStorage.setItem('token', authToken);
          });
          initUser(user.email);
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      }), [dispatch]);

  const initUser = async (email: string) => {
    try {
      const user = (await EmployeeService.findEmployeeByEmailAsync(email)).toLoginUser();
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: true,
          user
        }
      });

    } catch(e) {
      console.error(e);
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  
  }

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<any> => {
    dispatch({
      type: 'LOADING_CHANGE',
      payload: {
        isLoading: true
      }
    });

    const user = await firebase.auth().signInWithEmailAndPassword(email, password);

    dispatch({
      type: 'LOADING_CHANGE',
      payload: {
        isLoading: false
      }
    });

    return user;
  };

  const changeEmail = async (newEmail: string, oldEmail: string, password?: string): Promise<ChangeEmailResult> => {
    const auth = getAuth();
    let user = auth.currentUser;

    if (password) {
      user = (await firebase.auth().signInWithEmailAndPassword(oldEmail, password)).user;
      if (!user) {
        return 'bad_password';
      }
    }

    const result = await updateEmail(user, newEmail).then((_: any) => 'success').catch(error => {
      if (error?.message?.includes('auth/requires-recent-login')) {
        return 'login_required';
      }
      return 'error';
    });

    return result as ChangeEmailResult;
  }

  const getCurrentUser = async (): Promise<firebase.User> => firebase.auth().currentUser;

  const recoveryPassword = async (email: string): Promise<void> => {
    firebase.auth().sendPasswordResetEmail(email);
  }

  const signInWithGoogle = (): Promise<any> => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<any> => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const logout = (): Promise<void> => {
    return firebase.auth().signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Firebase',
        changeEmail,
        createUserWithEmailAndPassword,
        getCurrentUser,
        signInWithEmailAndPassword,
        signInWithGoogle,
        recoveryPassword,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
