import { InternFilter, CreateInternDto, UpdateInternDto, AddDrugToInternDto, AddIncidentToInternDto, AddMedicalReportToInternDto, RemoveDrugFromInternDto, RemoveIncidentFromInternDto, RemoveMedicalReportFromInternDto } from './InternDto';
import { Intern } from '../domain/entities/Intern';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'src/config/http';
import { SearchResponse } from '../../commons/Types';
export abstract class InternService {

    public static async addDrugToInternAsync(addDrug: AddDrugToInternDto): Promise<Intern> {
        return Intern.fromJson(await postRequest(`/interns/${addDrug.internId}/drugs`, addDrug));
    }

    public static async addIncidentToInternAsync(addIncident: AddIncidentToInternDto): Promise<Intern> {
        return Intern.fromJson(await postRequest(`/interns/${addIncident.internId}/incidents`, addIncident));
    }

    public static async addMedicalReportToInternAsync(addMedicalReport: AddMedicalReportToInternDto): Promise<Intern> {
        return Intern.fromJson(await postRequest(`/interns/${addMedicalReport.internId}/medical_reports`, addMedicalReport));
    }

    public static async createInternAsync(createIntern: CreateInternDto): Promise<Intern> {
        return Intern.fromJson(await postRequest('/interns', createIntern));
    }

    public static async deleteInternAsync(internId: string): Promise<Intern> {
        return Intern.fromJson(await deleteRequest(`/interns/${internId}`));
    }

    public static async findInternByIdAsync(internId: string): Promise<Intern> {
        return Intern.fromJson(await getRequest(`/interns/${internId}`));
    }

    public static async searchInternsAsync(filters: InternFilter): Promise<SearchResponse<Intern>> {
        const response: SearchResponse<Intern> = await getRequest('/interns', JSON.parse(JSON.stringify(filters)), {}, true);
        return {
            data: Intern.fromJsonArray(response.data),
            totalCount: response.totalCount
        };
    }

    public static async updateInternAsync(updateIntern: UpdateInternDto): Promise<Intern> {
        return Intern.fromJson(await patchRequest('/interns', updateIntern));
    }

    public static async removeDrugFromInternAsync(removeDrug: RemoveDrugFromInternDto): Promise<Intern> {
        return Intern.fromJson(await deleteRequest(`/interns/${removeDrug.internId}/drugs/${removeDrug.drugId}`, removeDrug));
    }

    public static async removeIncidentFromInternAsync(removeIncident: RemoveIncidentFromInternDto): Promise<Intern> {
        return Intern.fromJson(await deleteRequest(`/interns/${removeIncident.internId}/incidents/${removeIncident.incidentId}`, removeIncident));
    }

    public static async removeMedicalReportFromInternAsync(removeMedicalReport: RemoveMedicalReportFromInternDto): Promise<Intern> {
        return Intern.fromJson(await deleteRequest(`/interns/${removeMedicalReport.internId}/medical_reports/${removeMedicalReport.medicalReportId}`, removeMedicalReport));
    }
}