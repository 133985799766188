import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import activityReducer from 'src/features/activity/data/ActivitySlice';
import centerReducer from 'src/features/center/data/CenterSlice';
import employeeReducer from 'src/features/employee/data/EmployeeSlice';
import groupReducer from 'src/features/activity/data/GroupSlice';
import internReducer from 'src/features/intern/data/InternSlice';
import menuReducer from 'src/features/menu/data/MenuSlice';
import relativeReducer from 'src/features/relative/data/RelativeSlice';
import logReducer from 'src/features/log/data/LogSlice';

export const store = configureStore({
  reducer: {
    activity: activityReducer,
    center: centerReducer,
    employee: employeeReducer,
    group: groupReducer,
    menu: menuReducer,
    intern: internReducer,
    relative: relativeReducer,
    log: logReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
