const esJSON = {

  /* :: ACTIVITIES :: */
  Activities: {
    AddNew: 'Nueva actividad',
    Edit: 'Editar actividad',
    ManageSuccess: "Actividad guardada",
    DeleteSuccess: 'Turno borrado',
    Name: 'Nombre',
    Notes: 'Notas',
    NoResults: 'No hay actividades que mostrar',
    Search: 'Buscar actividad por nombre',
    Summary: 'Desde aquí podrás gestionar las actividades del centro. Crea, edita y borra actividades o selecciona una actividad para gestionar los grupos, residentes y turnos.',
    Title: 'Actividades',
    Errors: {
      Manage: "No se pudo completar la acción",
      Delete: "No se pudo eliminar la actividad"
    },
    Details: {
      Summary: "Aquí podrás controlar los grupos de la actividad, así como los turnos de cada grupo y sus integrantes"
    },
    SeeMore: "Ver grupos y turnos",

  },

  /* :: COMMONS :: */

  Commons: {

    Actions: 'Acciones',

    Cancel: 'Cancelar',
    ChangePassword: 'Cambiar Contraseña',
    ClickHere: 'clic aquí',
    ConfirmAction: 'Confirmar Acción',
    Confirm: 'Confirmar',

    Delete: 'Eliminar',
    Deleting: 'Eliminando',

    Edit: 'Editar',

    Gender: {
      Title: 'Género',

      female: 'Mujer',
      male: 'Hombre',
      none: 'Sin indicar'
    },

    Login: {
      Email: 'Email',
      EmailInstructions: 'Se ha enviado un email a tu correo eléctronico con las instrucciones para recuperar tu contraseña',
      EmailRecovery: 'Escribe tu email y te enviaremos un correo eléctronico para recuperar la contraseña',
      Errors: {
        Email: 'Debes introducir un email válido',
        Password: 'Debes introducir una contraseña válida',
        Terms: 'Debes aceptar los téminos y condiciones'
      },
      IAccept: 'Acepto',
      GoToLogin: 'Volver al login',
      LoginError: 'El email o la contraseña es incorrecta',
      LostPassword: 'Recuperar contraseña',
      Password: 'Contraseña',
      RecoverPassword: 'Recuperar contraseña',
      RecoverPassEmailSent: 'Comprueba la bandeja de entrada de tu correo eléctronico.',
      SignIn: 'Autenticarse',
      Subtitle: 'Accede con tu usuario y tu contraseña',
      TermsAndConditions: 'los téminos y condiciones del servicio',
      Title: 'Acceso',
      TrySignIn: 'Para acceder a la plataforma',
    },
    Logout: 'Cerrar Sesión',

    NotSpecified: 'Sin Indicar',

    PaginationPrep: 'de',
    PerPage: '{{resource}} por página',

    Save: 'Guardar',
    Saving: 'Guardando',
    Status: {
      active: 'Activo',
      all: 'Todos',
      inactive: 'Inactivo',
      pending: 'Pendiente',

      Title: 'Estado'
    },

    Upload: 'Subir Fichero',
    Uploading: 'Subiendo Fichero...',
  },

  /* :: DRUGS :: */

  Drugs: {
    AddNew: 'Añadir Medicina',

    Breakfast: 'Desayuno',

    Dinner: 'Cena',

    Edit: 'Editar Medicamento',
    EndDate: 'Fecha de fin',
    Errors: {
      Name: 'Introduce un nombre válido',
    },
    InitDate: 'Ficha de inicio',
    Lunch: 'Comida',
    MidMorning: 'Media mañana',
    ManageSuccess: 'Medicamento guardado correctamente',

    Name: 'Nombre del medicamento',
    NoResults: 'Ningún medicamento añadido',
    Notes: 'Notas',
    NotesPlaceHolder: 'Introduce notas adicionales sobre la pauta de medicación, dosis, etc.',
    Snack: 'Merienda',
    Summary: 'Desde aquí se gestionarán las medicaciones de los residentes. Se indican las tomas de desayuno, comida y cena',

    Title: 'Medicinas',
  },

  /* :: GROUPS :: */

  Groups: {
    AddNew: 'Nuevo grupo',
    DeleteSuccess: 'Grupo borrado',
    Title: 'Grupos',
    Empty: 'Aun no hay grupos para esta actividad',
    Name: 'Nombre del grupo',
    Notes: 'Notas',
    ManageSuccess: 'Grupo guardado correctamente',
    Edit: 'Editar grupo',
    Errors: {
      Name: 'Introduce un nombre válido',
      Delete: 'No se pudo borrar el grupo'
    },
    ManageMembers: {
      Title: 'Internos en el grupo',
      Action: 'Añadir un miembro'
    }
  },

  /* :: INCIDENTS :: */

  Incidents: {
    AddNew: 'Añadir Aviso',

    Date: 'Fecha',

    Edit: 'Editar Aviso',
    Errors: {
      DateTime: 'Debes introducir un día y una hora',
      Manage: 'No se ha podido guardar el aviso',
      Title: 'Introduce unos comentarios válidos',
    },

    ManageSuccess: 'Avisos actualizados correctamente',

    NoResults: 'Ningún aviso añadido',
    Notes: 'Comentarios para los tutores',

    Summary: 'Se añadirán avisos de los residentes que se notifican directamente a los tutores del mismo',

    Time: 'Hora',
    Title: 'Avisos'
  },

  /* :: INTERN DETAILS :: */

  InternDetails: {
    Title: 'Residente',

    Summary: 'Apartado de gestión de los residentes. Podrás ver y modificar sus datos personales, medicinas, informes médicos, avisos y tutores.'
  },

  /* :: INTERNS :: */

  Interns: {
    AddNew: 'Nuevo Residente',

    Birthday: 'Fecha de nacimiento',

    Edit: "Editar Residente",
    Errors: {
      Manage: 'No se ha podido guardar el residente',
      Name: 'Debe introducir un nombre válido',
      Surname: 'Debe introducir unos apellidos válidos',
    },

    Info: {
      Personal: 'Información personal',
      Subtitle: 'Desde aquí se puede gestionar la información personal del residente',
      Title: 'Información general',
    },

    ManageSuccess: 'Residente guardado correctamente',

    Name: "Nombre",
    Nif: 'DNI',
    NoResults: "Ningún residente encontrado con los filtros de búsqueda actuales",

    Observations: {
      Description: 'Desde aquí se podrán modificar las anotaciones del residente respecto a la medicación, alergias y cualquier otra caraterística de la que deban de ser informado el personal del centro',
      Edit: 'Modificar Observaciones',
      None: 'El residente no tiene ninguna observación especificada',
      Title: 'Observaciones',
    },

    Search: 'Busca residente por nombre...',
    SeeMore: 'Ver Detalles',
    Summary: 'Página de gestión de los residente de tu centro. Podrás añadir nuevos y gestionar sus medicaciones, avisos, menús, actividades, tutores e informes médicos para que sus tutores puedan acceder a través del servicio',
    Surname: 'Apellidos',

    Title: 'Residentes',
  },

  /* :: MEDICAL REPORTS :: */

  MedicalReports: {
    AddNew: 'Añadir Informe',

    ChangeFile: 'Cambiar Fichero',

    Edit: 'Editar Informe',
    Errors: {
      Manage: 'No se ha podido añadir el informe médico',
      Title: 'Introduce un título válido',
      Url: 'Debes subir un fichero válido'
    },

    ManageSuccess: 'Informes médicos actualizados correctamente',

    Name: 'Título del informe médico',
    NoResults: 'Ningún informe médico añadido',
    Notes: 'Notas para los tutores',

    Summary: 'Se añadirán informes médicos a los residentes que se notifican directamente a los tutores del mismo',

    Title: 'Informes Médicos'
  },

  /* :: MENUS :: */

  Menus: {
    AddNew: 'Subir Menú',

    ChangeFile: 'Cambiar Fichero',
    ChangeSuccess: 'Menú cambiado correctamente',
    CreatedAt: 'Fecha de Subida',

    Edit: 'Editar Menú',
    Errors: {
      Change: 'No se ha podido cambiar el menú del residente',
      Manage: 'No se ha podido guardar el menú',
      Remove: 'No se ha podido eliminar el menú',
      Title: 'Debes introducir un nombre válido',
      Url: 'Debes subir un fichero',
    },

    FileName: 'Nombre del fichero',

    ManageSuccess: 'Fichero guardado correctamente',

    Name: 'Nombre del menú',
    NoResults: 'Aún no se ha añadido ningún menú',
    Notes: 'Notas para los tutores',

    Of: 'Menús de {{resource}}',
    OpenFile: 'Abrir Fichero',

    RemoveConfirmText: 'Vas a eliminar un menú. Esta acción es irrecuperable. ¿Estás seguro?',
    RemoveFile: 'Eliminar Fichero',
    RemoveSuccess: 'Menú eliminado correctamente',

    Select: {
      NotSelected: 'Ningún menu seleccionado',
      Selected: 'Menú del residente',
      Subtitle: 'En esta sección podrás cambiar el menú del residente. Los tutores verán el menú seleccionado',
      Title: 'Menú del residente'
    },
    SelectToIntern: 'Seleccionar Menú',
    Summary: 'En esta sección podrán subir ficheros con los menús que luego tus empleados y los tutores de los clientes podrán consultar en el sistema',

    Title: 'Menús',
  },

  /* :: PAYROLLS :: */

  Payrolls: {
    AddNew: 'Subir Nómina',

    ChangeFile: 'Cambiar Fichero',
    CreatedAt: 'Fecha de Subida',

    Errors: {
      Manage: 'No se ha podido guardar el fichero',
      Remove: 'No se ha podido eliminar la nómina',
      Title: 'Debes introducir un nombre válido',
      Url: 'Debes subir un fichero',
    },

    FileName: 'Nombre del fichero',

    ManageSuccess: 'Fichero guardado correctamente',

    Name: 'Título',
    NoResults: 'Aún no se ha añadido ninguna nómina a este empleado',
    Notes: 'Notas para el empleado',

    Of: 'Nóminas de {{resource}}',
    OpenFile: 'Abrir Fichero',

    RemoveConfirmText: 'Vas a eliminar una nómina. Esta acción es irrecuperable. ¿Estás seguro?',
    RemoveFile: 'Eliminar Fichero',
    RemoveSuccess: 'Nómina eliminada correctamente',

    Summary: 'Podrás consular las nóminas del empleado así como añadir nuevas o eliminar las anteriores',

    Title: 'Nóminas',
  },

  /* :: RELATIVES :: */

  Relatives: {
    AddNew: 'Añadir Tutor',

    Edit: 'Editar Tutor',
    Email: 'Email',
    Errors: {
      Email: 'Debes introducir un email válido',
      Manage: 'No se ha podido guardar el tutor',
      Name: 'Debes introducir un nombre válido',
      Surname: 'Debes introducir unos apellidos válidos',
    },

    ManageSuccess: 'Tutor actualizado correctamente',

    Name: 'Nombre del Tutor',
    NoResults: 'Ningún tutor añadido',

    Title: 'Tutores',

    Phone: 'Teléfono',

    Remove: 'Eliminar Tutor',

    Summary: 'Podrás añadir los tutores del residente. Ten en cuenta que dichos tutores podrán acceder a toda la información del servicio de los residentes que tengan asociados',
    Surname: 'Apellidos'
  },

  /* :: TURNS :: */

  Turns: {
    AddNew: 'Nuevo turno',
    DayOfWeek: 'Día de la semana',
    DeleteSuccess: 'Turno borrado',
    EndTime: "Hora de fin",
    Empty: 'Este grupo no tiene turnos todavía',
    Edit: 'Editar turno',
    Errors: {
      Name: 'Introduce un nombre válido',
      Group: "Debes seleccionar un grupo"
    },
    Group: "Grupo",
    InitTime: "Hora de inicio",
    Title: 'Turnos',
    Notes: 'Notas',
    ManageSuccess: 'Turno guardado correctamente',
    ManageMembers: {
      Title: 'Internos en el grupo',
      Action: 'Añadir un miembro'
    },
    Monday: "Lunes",
    Tuesday: "Martes",
    Wednesday: "Miércoles",
    Thursday: "Jueves",
    Friday: "Viernes",
    Saturday: "Sábado",
    Sunday: "Domingo"


  },
  /* :: WORKERS :: */

  Workers: {
    AddNew: 'Nuevo Empleado',

    Edit: "Editar Empleado",
    Email: "Email",
    Errors: {
      Email: 'Debe introducir un email válido',
      Manage: 'No se ha podido guardar el empleado',
      Name: 'Debe introducir un nombre válido',
      Phone: 'Debe introducir un teléfono válido',
      Surname: 'Debe introducir unos apellidos válidos',
    },

    ManageSuccess: 'Empleado guardado correctamente',

    Name: "Nombre",
    NoResults: "Ningún empleado encontrado con los filtros de búsqueda actuales",

    Payrolls: 'Nóminas',
    Phone: 'Teléfono',

    Search: 'Busca empleado por nombre...',
    Summary: 'Página de gestión de los empleados de tu centro. Podrás añadir nuevos y subirles sus nóminas para que puedan consultarlas desde la aplicación móvil.',
    Surname: 'Apellidos',

    Title: 'Empleados',
  }

}

export default esJSON;