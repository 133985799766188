import { NotArrayError } from '../../../../app/errors/NotArrayError';
export class Payroll {
    constructor(
        readonly id: string,
        readonly createdAt: Date,
        readonly employeeId: string,
        readonly url: string,
        readonly title: string,
        readonly notes?: string
    ) { }

    public static fromJson(json: any): Payroll {
        return new Payroll(
            json['id'],
            new Date(json['createdAt']),
            json['employeeId'],
            json['url'],
            json['title'],
            json['notes'],
        );
    }

    public static fromJsonArray(jsonArray: any): Payroll[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}