import { Status } from "src/features/commons/Status";
import { Address } from "src/features/address/entities/Address";
import { DescriptionBlock } from "./DescriptionBlock";
import { NotArrayError } from "src/app/errors/NotArrayError";

export class Center {
    constructor(
        readonly id: string,
        public address: Address,
        public descriptionBlocks: DescriptionBlock[],
        public email: string,
        public logoUrl: string,
        public name: string,
        public phone: string,
        public photoUrl: string,
        public status: Status,
        public theme: string
    ) { }

    public static fromJson(json: any): Center {
        return new Center(
            json['id'],
            Address.fromJson(json['address']),
            DescriptionBlock.fromJsonArray(json['descriptionBlocks']),
            json['email'],
            json['logoUrl'],
            json['name'],
            json['phone'],
            json['photoUrl'],
            Status.fromString(json['status']),
            json['theme']
        );
    }

    public static fromJsonArray(jsonArray: any): Center[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}