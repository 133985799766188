export class EmployeeRole {

    static readonly Admin = new EmployeeRole('admin');

    static readonly Worker = new EmployeeRole('worker');

    constructor(public readonly value: string) { }

    static getValues(): EmployeeRole[] {
        return [this.Admin, this.Worker];
    }

    static getStringValues(): string[] {
        return ['admin', 'worker']
    }

    static fromString(string: string): EmployeeRole {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): ∫ does not correspond to any instance of the enum ${(this as any).prototype.constructor.name
            }`
        );
    }
}