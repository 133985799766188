import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Action, StateStatus } from "src/features/commons/Entities";
import { SearchResponse } from "src/features/commons/Types";
import { RootState } from "../../../config/store";
import { Activity } from "../domain/entities/Activity";
import { ActivityFilter } from "./ActivityDto";
import {
  addTurnThunk,
  createActivityThunk,
  createGroupThunk,
  deleteActivityThunk,
  deleteGroupThunk,
  deleteTurnThunk,
  findActivityByIdThunk,
  searchActivitiesThunk,
  updateActivityThunk,
  updateGroupThunk,
  updateTurnThunk,
} from "./ActivityThunk";

interface ActivityState {
  activityAction: Action;
  activities: Activity[];
  activitySelected?: Activity;
  filter: ActivityFilter;
  status: StateStatus;
  totalCount: number;
}

export const initialState: ActivityState = {
  activityAction: "none",
  activities: [],
  activitySelected: undefined,
  filter: {
    searchText: "",
    skip: 0,
    status: 'active',
    take: 10,
  },
  status: "ready",
  totalCount: 0,
};

const activitySlice = createSlice({
  name: "activities",
  initialState,
  reducers: {
    changeActivityAction: (
      state: ActivityState,
      action: PayloadAction<Action>
    ) => {
      state.activityAction = action.payload;
    },
    selectActivity: (
      state: ActivityState,
      action: PayloadAction<Activity | undefined>
    ) => {
      state.activitySelected = action.payload;
    },
    changeFilterText: (state: ActivityState, action: PayloadAction<string>) => {
      state.filter = {
        ...state.filter,
        skip: 0,
        searchText: action.payload,
      };
    },
    changeFilterStatus: (
      state: ActivityState,
      action: PayloadAction<string | null>
    ) => {
      state.filter = {
        ...state.filter,
        status: action.payload,
      };
    },

    changeFilterSkip: (state: ActivityState, action: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        skip: action.payload,
      };
    },

    changeFilterTake: (state: ActivityState, action: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        skip: 0,
        take: action.payload,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ActivityState>) => {
    const updateActivity = (
      state: ActivityState,
      action: PayloadAction<Activity>
    ) => {
      state.activities = state.activities.map((activity) =>
        activity.id === action.payload.id ? action.payload : activity
      );
      if (state.activitySelected?.id == action.payload.id) {
        state.activitySelected = action.payload;
      }
      state.status = "ready";
    };

    /* 
            Add Turn
        */

    builder
      .addCase(addTurnThunk.pending, (state: ActivityState) => {
        state.status = "loading";
      })
      .addCase(
        addTurnThunk.fulfilled,
        (state: ActivityState, action: PayloadAction<Activity>) => {
          updateActivity(state, action);
        }
      )
      .addCase(addTurnThunk.rejected, (state: ActivityState) => {
        state.status = "error";
      });

    /*
            Create Activity
        */
    builder
      .addCase(createActivityThunk.pending, (state: ActivityState) => {
        state.status = "loading";
      })
      .addCase(
        createActivityThunk.fulfilled,
        (state: ActivityState, action: PayloadAction<Activity>) => {
          state.activities.unshift(action.payload);
          state.activitySelected = undefined;
          state.status = "ready";
        }
      )
      .addCase(createActivityThunk.rejected, (state: ActivityState) => {
        state.status = "error";
      });

    /*
            Create Group
        */

    builder
      .addCase(createGroupThunk.pending, (state: ActivityState) => {
        state.status = "loading";
      })
      .addCase(
        createGroupThunk.fulfilled,
        (state: ActivityState, action: PayloadAction<Activity>) => {
          updateActivity(state, action);
        }
      )
      .addCase(createGroupThunk.rejected, (state: ActivityState) => {
        state.status = "error";
      });

    /*
            Delete Activity
        */
    builder
      .addCase(deleteActivityThunk.pending, (state: ActivityState) => {
        state.status = "loading";
      })
      .addCase(
        deleteActivityThunk.fulfilled,
        (state: ActivityState, action: PayloadAction<Activity>) => {
          state.status = "ready";
          state.activities = state.activities.filter(
            (activity) => activity.id !== action.payload.id
          );
          if (state.activitySelected?.id === action.payload.id) {
            state.activitySelected = undefined;
          }
          state.activityAction = "none";
        }
      )
      .addCase(deleteActivityThunk.rejected, (state: ActivityState) => {
        state.status = "error";
      });

    /*
            Delete Group
        */
    builder
      .addCase(deleteGroupThunk.pending, (state: ActivityState) => {
        state.status = "loading";
      })
      .addCase(
        deleteGroupThunk.fulfilled,
        (state: ActivityState, action: PayloadAction<Activity>) => {
          updateActivity(state, action);
        }
      )
      .addCase(deleteGroupThunk.rejected, (state: ActivityState) => {
        state.status = "error";
      });

    /*
            Delete Turn
        */
    builder
      .addCase(deleteTurnThunk.pending, (state: ActivityState) => {
        state.status = "loading";
      })
      .addCase(
        deleteTurnThunk.fulfilled,
        (state: ActivityState, action: PayloadAction<Activity>) => {
          updateActivity(state, action);
        }
      )
      .addCase(deleteTurnThunk.rejected, (state: ActivityState) => {
        state.status = "error";
      });

    /*
            Find Activity By Id
        */
    builder
      .addCase(findActivityByIdThunk.pending, (state: ActivityState) => {
        state.status = "loading";
      })
      .addCase(
        findActivityByIdThunk.fulfilled,
        (state: ActivityState, action: PayloadAction<Activity>) => {
          state.activitySelected = action.payload;
          state.status = "ready";
        }
      )
      .addCase(findActivityByIdThunk.rejected, (state: ActivityState) => {
        state.status = "error";
      });

    /*
             Search Activities
        */
    builder
      .addCase(searchActivitiesThunk.pending, (state: ActivityState) => {
        state.status = "loading";
      })
      .addCase(
        searchActivitiesThunk.fulfilled,
        (
          state: ActivityState,
          action: PayloadAction<SearchResponse<Activity>>
        ) => {
          state.activities = action.payload.data;
          state.totalCount = action.payload.totalCount;
          state.status = "ready";
        }
      )
      .addCase(searchActivitiesThunk.rejected, (state: ActivityState) => {
        state.status = "error";
      });

    /*
            Update Activity
        */
    builder
      .addCase(updateActivityThunk.pending, (state: ActivityState) => {
        state.status = "loading";
      })
      .addCase(
        updateActivityThunk.fulfilled,
        (state: ActivityState, action: PayloadAction<Activity>) => {
          updateActivity(state, action);
        }
      )
      .addCase(updateActivityThunk.rejected, (state: ActivityState) => {
        state.status = "error";
      });

    /*
            Update Group
        */
    builder
      .addCase(updateGroupThunk.pending, (state: ActivityState) => {
        state.status = "loading";
      })
      .addCase(
        updateGroupThunk.fulfilled,
        (state: ActivityState, action: PayloadAction<Activity>) => {
          updateActivity(state, action);
        }
      )
      .addCase(updateGroupThunk.rejected, (state: ActivityState) => {
        state.status = "error";
      });

    /*
            Update Turn
        */
    builder
      .addCase(updateTurnThunk.pending, (state: ActivityState) => {
        state.status = "loading";
      })
      .addCase(
        updateTurnThunk.fulfilled,
        (state: ActivityState, action: PayloadAction<Activity>) => {
          updateActivity(state, action);
        }
      )
      .addCase(updateTurnThunk.rejected, (state: ActivityState) => {
        state.status = "error";
      });
  },
});

const {
  changeActivityAction,
  changeFilterSkip,
  changeFilterStatus,
  changeFilterTake,
  changeFilterText,
  selectActivity,
} = activitySlice.actions;

const getActivityAction = (state: RootState) => state.activity.activityAction;
const getActivities = (state: RootState) => state.activity.activities;
const getActivityFilter = (state: RootState) => state.activity.filter;
const getActivitySelected = (state: RootState) =>
  state.activity.activitySelected;
const getStatus = (state: RootState) => state.activity.status;
const getTotalCount = (state: RootState) => state.activity.totalCount;

export {
  changeActivityAction,
  changeFilterSkip,
  changeFilterStatus,
  changeFilterTake,
  changeFilterText,
  selectActivity,
  getActivityAction,
  getActivityFilter,
  getActivities,
  getActivitySelected,
  getStatus,
  getTotalCount,
};

export default activitySlice.reducer;
