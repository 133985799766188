import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Formik } from 'formik';
import * as Yup from "yup";
import { FormControl, Grid, useTheme, Zoom } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FC } from 'react';
import DialogTitleWithIcon from 'src/app/components/DialogTitleWithIcon';
import { useAppDispatch } from 'src/config/hooks';
import useAuth from 'src/hooks/useAuth';
import { useAppSelector } from '../../../config/hooks';
import { ChangeEmailResult } from 'src/features/commons/Entities';
import { updateEmployeeThunk } from '../../../features/employee/data/EmployeeThunk';
import { getStatus } from 'src/features/employee/data/EmployeeSlice';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

interface EditEmailAndPasswordDialogProps {
    readonly open: boolean;
    readonly onClose: () => void;
}

const EditEmailAndPasswordDialog: FC<EditEmailAndPasswordDialogProps> = ({open, onClose}) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const { user, changeEmail } = useAuth();
    const status = useAppSelector(getStatus);
    console.log(status);
    const isLoading: boolean = useAppSelector(getStatus) === 'loading';

    const profileInfoSchema = Yup.object({
        email: Yup.string().email('Debes introducir un email válido').required('Introduce un email válido'),
    });

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='md'
        >
            <DialogTitleWithIcon
                title={"Cambiar Contraseña"}
                icon={CloseIcon}
                onClick={onClose}
            />

            <Formik
                initialValues={{
                    email: user?.email ?? '',
                    password: '',
                    confirmPass: '',
                }}
                validationSchema={profileInfoSchema}
                onSubmit={async (values, { setErrors }) => {
                    if (values.password !== values.confirmPass) {
                        setErrors({ confirmPass: 'Las contraseñas no coinciden'});
                        return;
                    }
                    let updatedEmailResult: ChangeEmailResult = 'success';

                    if (user?.email !== values.email) {
                        updatedEmailResult = await changeEmail(values.email, user?.email, values.password);
                        if (updatedEmailResult === 'bad_password') {
                            setErrors({ password: 'La contraseña introducida no es correcta' });
                        }
                    }

                    if (updatedEmailResult === 'success') {
                        dispatch(updateEmployeeThunk({
                            ...values,
                            id: user?.id ?? ''
                        })).then(result => { 
                            if (result.type.includes('reject')) {
                                enqueueSnackbar(
                                    t('Relatives.Errors.Manage'),
                                    {
                                        variant: 'error',
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        },
                                        TransitionComponent: Zoom
                                    });
                            } else {
                                enqueueSnackbar(
                                    t('Relatives.ManageSuccess'),
                                    {
                                        variant: 'success',
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        },
                                        TransitionComponent: Zoom
                                    });
                                onClose();
                            }
                         });
                    }
                }}>

                {
                    props => (
                        <form onSubmit={props.handleSubmit}>
                            <DialogContent
                                dividers
                                sx={{ paddingLeft: "2%", paddingRight: "2%" }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                key="email"
                                                fullWidth
                                                color="secondary"
                                                label="Email"
                                                variant="outlined"
                                                name="email"
                                                {...props.getFieldProps('email')}
                                                helperText={props.touched.email && props.errors.email}
                                                error={props.touched.email && props.errors.email !== undefined}
                                            />
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        key="password"
                                                        fullWidth
                                                        color="secondary"
                                                        label="Contraseña"
                                                        variant="outlined"
                                                        type='password'
                                                        name="password"
                                                        {...props.getFieldProps('password')}
                                                        helperText={props.touched.password && props.errors.password}
                                                        error={props.touched.password && props.errors.password !== undefined}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        key="confirmPass"
                                                        fullWidth
                                                        color="secondary"
                                                        label="Confirmar Contraseña"
                                                        variant="outlined"
                                                        type='password'
                                                        name="confirmPass"
                                                        {...props.getFieldProps('confirmPass')}
                                                        helperText={props.touched.confirmPass && props.errors.confirmPass}
                                                        error={props.touched.confirmPass && props.errors.confirmPass !== undefined}
                                                    />
                                                </FormControl>
                                            </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => onClose()}
                                    disabled={isLoading}>
                                    Cerrar
                                </Button>
                                <Button
                                    disabled={isLoading}
                                    type={"submit"}
                                    sx={{
                                        alignSelf: 'center',
                                        fontWeight: 'normal',
                                        backgroundColor: `${theme.colors.primary.lighter}`,
                                        '&:hover': {
                                            backgroundColor: `${theme.colors.primary.dark}`,
                                            color: `${theme.palette.getContrastText(
                                                theme.colors.primary.main
                                            )}`
                                        }
                                    }}
                                >
                                    { isLoading ? 'Guardando...' : 'Guardar' }
                                </Button>
                            </DialogActions>
                        </form>
                    )
                }
            </Formik>
        </Dialog >

    );
}

export default EditEmailAndPasswordDialog;