import { EmployeeFilter, CreateEmployeeDto, UpdateEmployeeDto, AddPayrollDto, RemovePayrollDto } from './EmployeeDto';
import { Employee } from '../domain/entities/Employee';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'src/config/http';
import { SearchResponse } from '../../commons/Types';
export abstract class EmployeeService {

    public static async addPayrollToEmployeeAsync(addPayroll: AddPayrollDto): Promise<Employee> {
        return Employee.fromJson(await postRequest(`/employees/${addPayroll.employeeId}/payrolls`, addPayroll));
    }

    public static async createEmployeeAsync(createEmployee: CreateEmployeeDto): Promise<Employee> {
        return Employee.fromJson(await postRequest('/employees', createEmployee));
    }

    public static async deleteEmployeeAsync(employeeId: string): Promise<Employee> {
        return Employee.fromJson(await deleteRequest(`/employees/${employeeId}`));
    }

    public static async removePayrollFromEmployeeAsync(deletePayroll: RemovePayrollDto): Promise<Employee> {
        return Employee.fromJson(await deleteRequest(`/employees/${deletePayroll.employeeId}/payrolls/${deletePayroll.payrollId}`));
    }

    public static async findEmployeeByIdAsync(employeeId: string): Promise<Employee> {
        return Employee.fromJson(await getRequest(`/employees/${employeeId}`));
    }

    public static async findEmployeeByEmailAsync(email: string): Promise<Employee> {
        return Employee.fromJson(await getRequest(`/employees?email=${email}`));
    }

    public static async searchEmployeesAsync(filters: EmployeeFilter): Promise<SearchResponse<Employee>> {
        const response: SearchResponse<Employee> = await getRequest('/employees', JSON.parse(JSON.stringify(filters)), {}, true);
        return {
            data: Employee.fromJsonArray(response.data),
            totalCount: response.totalCount
        }
    }

    public static async updateEmployeeAsync(updateEmployee: UpdateEmployeeDto): Promise<Employee> {
        return Employee.fromJson(await patchRequest('/employees', updateEmployee));
    }
}