import { MenuFilter, CreateMenuDto, UpdateMenuDto } from './MenuDto';
import { Menu } from '../domain/entities/Menu';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'src/config/http';
import { SearchResponse } from '../../commons/Types';

export abstract class MenuService {

    public static async createMenuAsync(createMenu: CreateMenuDto): Promise<Menu> {
        return Menu.fromJson(await postRequest('/menus', createMenu));
    }

    public static async deleteMenuAsync(menuId: string): Promise<Menu> {
        return Menu.fromJson(await deleteRequest(`/menus/${menuId}`));
    }

    public static async findMenuByIdAsync(menuId: string): Promise<Menu> {
        return Menu.fromJson(await getRequest(`/menus/${menuId}`));
    }

    public static async searchMenusAsync(filters: MenuFilter): Promise<SearchResponse<Menu>> {
        const response: SearchResponse<Menu> = await getRequest('/menus', JSON.parse(JSON.stringify(filters)), {}, true);
        return {
            data: Menu.fromJsonArray(response.data),
            totalCount: response.totalCount
        }
    }

    public static async updateMenuAsync(updateMenu: UpdateMenuDto): Promise<Menu> {
        return Menu.fromJson(await patchRequest('/menus', updateMenu));
    }
}