import { Drug } from "./Drug";
import { InternGender } from './InternGender';
import { Incident } from './Incident';
import { MedicalReport } from './MedicalReport';
import { Menu } from '../../../menu/domain/entities/Menu';
import { Relative } from '../../../relative/domain/entities/Relative';
import { NotArrayError } from "src/app/errors/NotArrayError";
import { Status } from "src/features/commons/Status";

export class Intern {
    constructor(
        readonly id: string,
        public name: string,
        public drugs: Drug[],
        public incidents: Incident[],
        public medicalReports: MedicalReport[],
        public relatives: Relative[],
        public status: Status,

        public birthday?: Date,
        public gender?: InternGender,
        public menu?: Menu,
        public nif?: string,
        public observations?: string,
        public photoUrl?: string,
        public surname?: string
    ) { }

    public getFullName(): string {
        if (this.surname) {
            return `${this.name} ${this.surname}`;
        }

        return this.name;
    }

    public getInitials(): string {
        if (this.surname) {
            return `${this.name.charAt(0).toUpperCase()}${this.surname.charAt(0).toUpperCase()}`;
        }

        return `${this.name.charAt(0).toUpperCase()}${this.name.charAt(1).toUpperCase()}`;
    }

    public static fromJson(json: any): Intern {
        return new Intern(
            json['id'],
            json['name'],
            Drug.fromJsonArray(json['drugs']),
            Incident.fromJsonArray(json['incidents']),
            MedicalReport.fromJsonArray(json['medicalReports']),
            Relative.fromJsonArray(json['relatives']),
            Status.fromString(json['status']),
            json['birthday'],
            json['gender'] ? InternGender.fromString(json['gender']) : undefined,
            json['menu'] ? Menu.fromJson(json['menu']) : undefined,
            json['nif'],
            json['observations'],
            json['photoUrl'],
            json['surname'],
        );
    }
    
    public static fromJsonArray(jsonArray: any): Intern[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}