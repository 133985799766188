import { createAsyncThunk } from '@reduxjs/toolkit';
import { Menu } from '../domain/entities/Menu';
import { CreateMenuDto, UpdateMenuDto, MenuFilter } from './MenuDto';
import { MenuService } from './MenuService';
import { SearchResponse } from '../../commons/Types';

export const createMenuThunk = createAsyncThunk(
    'menu/create',
    async (createMenu: CreateMenuDto): Promise<Menu> =>
        MenuService.createMenuAsync(createMenu)
);

export const deleteMenuThunk = createAsyncThunk(
    'menu/delete',
    async (menuId: string) => MenuService.deleteMenuAsync(menuId)
);

export const searchMenusThunk = createAsyncThunk(
    'menu/search',
    async (filters: MenuFilter): Promise<SearchResponse<Menu>> => MenuService.searchMenusAsync(filters)
);

export const updateMenuThunk = createAsyncThunk(
    'menu/update',
    async (updateMenu: UpdateMenuDto): Promise<Menu> =>
        MenuService.updateMenuAsync(updateMenu)
);

export const findMenuByIdThunk = createAsyncThunk(
    'menu/find_by_id',
    async (menuId: string): Promise<Menu> => MenuService.findMenuByIdAsync(menuId)
);