import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { SearchResponse } from "../../commons/Types";
import { Log } from "../domain/Log";
import { LogFilter } from "./LogDto";
import { searchLogsThunk } from "./LogThunk";

interface LogState {
  logs: Log[];
  filter: LogFilter;
  totalCount: number;
  status: StateStatus;
}

export const initialState: LogState = {
  logs: [],
  filter: {
    skip: 0,
    take: 10,
  },
  totalCount: 0,
  status: "ready",
};

const logSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {


    changeFilterSkip: (state: LogState, action: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        skip: action.payload,
      };
    },

    changeFilterTake: (state: LogState, action: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        skip: 0,
        take: action.payload,
      };
    },

  },
  extraReducers: (builder: ActionReducerMapBuilder<LogState>) => {
    /*
            Search Employees
       */
    builder
      .addCase(searchLogsThunk.pending, (state: LogState) => {
        state.status = "loading";
      })
      .addCase(
        searchLogsThunk.fulfilled,
        (
          state: LogState,
          action: PayloadAction<SearchResponse<Log>>
        ) => {
          state.logs = action.payload.data;
          state.totalCount = action.payload.totalCount;
          state.status = "ready";
        }
      )
      .addCase(searchLogsThunk.rejected, (state: LogState) => {
        state.status = "error";
      });
  },
});

const {
  changeFilterSkip,
  changeFilterTake,
} = logSlice.actions;

const getLogFilter = (state: RootState) => state.log.filter;
const getLogs = (state: RootState) => state.log.logs;
const getStatus = (state: RootState) => state.log.status;
const getTotalCount = (state: RootState) => state.log.totalCount;

export {
  changeFilterSkip,
  changeFilterTake,
  getLogFilter,
  getLogs,
  getStatus,
  getTotalCount,
};

export default logSlice.reducer;
