import { ActivityFilter, CreateActivityDto, UpdateActivityDto, CreateGroupDto, AddTurnDto, RemoveGroupDto, RemoveTurnDto, UpdateGroupDto, UpdateTurnDto } from './ActivityDto';
import { Activity } from '../domain/entities/Activity';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'src/config/http';
import { SearchResponse } from 'src/features/commons/Types';

export abstract class ActivityService {

    public static async addTurnAsync(addTurn: AddTurnDto): Promise<Activity> {
        return Activity.fromJson(await postRequest(`/activities/${addTurn.activityId}/groups/${addTurn.groupId}/turns`, addTurn));
    }

    public static async createActivityAsync(createActivity: CreateActivityDto): Promise<Activity> {
        return Activity.fromJson(await postRequest('/activities', createActivity));
    }

    public static async createGroupAsync(createGroup: CreateGroupDto): Promise<Activity> {
        return Activity.fromJson(await postRequest(`/activities/${createGroup.activityId}/groups`, createGroup));
    }

    public static async deleteActivityAsync(activityId: string): Promise<Activity> {
        return Activity.fromJson(await deleteRequest(`/activities/${activityId}`));
    }

    public static async deleteGroupAsync(removeGroup: RemoveGroupDto): Promise<Activity> {
        return Activity.fromJson(await deleteRequest(`/activities/${removeGroup.activityId}/groups/${removeGroup.groupId}`));
    }

    public static async deleteTurnAsync(removeTurn: RemoveTurnDto): Promise<Activity> {
        return Activity.fromJson(await deleteRequest(`/activities/${removeTurn.activityId}/groups/${removeTurn.groupId}/turns/${removeTurn.id}`));
    }

    public static async findActivityByIdAsync(activityId: string): Promise<Activity> {
        return Activity.fromJson(await getRequest(`/activities/${activityId}`));
    }

    public static async searchActivitiesAsync(filters: ActivityFilter): Promise<SearchResponse<Activity>> {
        const response: SearchResponse<Activity> = await getRequest('/activities', JSON.parse(JSON.stringify(filters)), {}, true);
        return {
            data: Activity.fromJsonArray(response.data),
            totalCount: response.totalCount
        };
    }

    public static async updateActivityAsync(updateActivity: UpdateActivityDto): Promise<Activity> {
        return Activity.fromJson(await patchRequest('/activities', updateActivity));
    }

    public static async updateGroupAsync(updateGroup: UpdateGroupDto): Promise<Activity> {
        return Activity.fromJson(await patchRequest(`/activities/${updateGroup.activityId}/groups/${updateGroup.id}`, updateGroup));
    }

    public static async updateTurnAsync(updateTurn: UpdateTurnDto): Promise<Activity> {
        return Activity.fromJson(await patchRequest(`/activities/${updateTurn.activityId}/groups/${updateTurn.groupId}/turns/${updateTurn.id}`, updateTurn));
    }
}