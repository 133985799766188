import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/config/store";
import { Intern } from "src/features/intern/domain/entities/Intern";
import { Group } from "../domain/entities/Group";

interface GroupState {
  groupFilter: Group[];
  groupSelected?: Group;
  groupMembers: Intern[];
}

export const initialState: GroupState = {
  groupFilter: [],
  groupSelected: undefined,
  groupMembers: [],
};

const groupSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    selectGroup: (
      state: GroupState,
      action: PayloadAction<Group | undefined>
    ) => {
      state.groupSelected = action.payload;
    },
    filterGroups: (
      state: GroupState,
      action: PayloadAction<Group[] | undefined>
    ) => {
      state.groupFilter = action.payload;
    },
    selectGroupMembers: (
      state: GroupState,
      action: PayloadAction<Intern[]>
    ) => {
      state.groupMembers = action.payload;
    },
  },
});

const { selectGroup, filterGroups, selectGroupMembers } = groupSlice.actions;

const getGroupFilter = (state: RootState) => state.group.groupFilter;
const getGroupSelected = (state: RootState) => state.group.groupSelected;
const getGroupMembers = (state: RootState) => state.group.groupMembers;

export {
  filterGroups,
  selectGroup,
  selectGroupMembers,
  getGroupFilter,
  getGroupSelected,
  getGroupMembers,
};

export default groupSlice.reducer;
