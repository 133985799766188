import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { Action, StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { Intern } from "../domain/entities/Intern";
import {
  addDrugToInternThunk,
  addIncidentToInternThunk,
  addMedicalReportToInternThunk,
  createInternThunk,
  deleteInternThunk,
  findInternByIdThunk,
  removeDrugFromInternThunk,
  removeIncidentFromInternThunk,
  removeMedicalReportFromInternThunk,
  searchInternsThunk,
  updateInternThunk,
} from "./InternThunk";
import { InternFilter } from "./InternDto";
import { SearchResponse } from "../../commons/Types";

export type DrugStatusType = 'active' | 'all'

interface InternState {
  internAction: Action;
  interns: Intern[];
  internSelected?: Intern;
  filter: InternFilter;
  status: StateStatus;
  totalCount: number;
  drugStatus: DrugStatusType;
}

export const initialState: InternState = {
  internAction: "none",
  interns: [],
  internSelected: undefined,
  filter: {
    text: "",
    skip: 0,
    status: 'active',
    take: 10,
  },
  status: "ready",
  totalCount: 0,
  drugStatus: 'all'
};

const internSlice = createSlice({
  name: "interns",
  initialState,
  reducers: {
    changeDrugStatus: (state: InternState, action: PayloadAction<DrugStatusType>) => {
      state.drugStatus = action.payload;
    },

    changeInternAction: (state: InternState, action: PayloadAction<Action>) => {
      state.internAction = action.payload;
    },

    changeFilter: (state: InternState, action: PayloadAction<InternFilter>) => {
      state.filter = action.payload;
    },

    changeFilterText: (state: InternState, action: PayloadAction<string>) => {
      state.filter = {
        ...state.filter,
        skip: 0,
        text: action.payload,
      };
    },

    changeFilterStatus: (
      state: InternState,
      action: PayloadAction<string | null>
    ) => {
      state.filter = {
        ...state.filter,
        status: action.payload,
      };
    },

    changeFilterSkip: (state: InternState, action: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        skip: action.payload,
      };
    },

    changeFilterTake: (state: InternState, action: PayloadAction<number>) => {
      state.filter = {
        ...state.filter,
        skip: 0,
        take: action.payload,
      };
    },

    selectIntern: (
      state: InternState,
      action: PayloadAction<Intern | undefined>
    ) => {
      state.internSelected = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InternState>) => {
    const updateIntern = (
      state: InternState,
      action: PayloadAction<Intern>
    ) => {
      state.interns = state.interns.map((intern) =>
        intern.id === action.payload.id ? action.payload : intern
      );
      if (state.internSelected?.id == action.payload.id) {
        state.internSelected = action.payload;
      }
      state.status = "ready";
    };

    /*
            Add Incident
        */
    builder
      .addCase(addDrugToInternThunk.pending, (state: InternState) => {
        state.status = "loading";
      })
      .addCase(
        addDrugToInternThunk.fulfilled,
        (state: InternState, action: PayloadAction<Intern>) => {
          updateIntern(state, action);
        }
      )
      .addCase(addDrugToInternThunk.rejected, (state: InternState) => {
        state.status = "error";
      });

    /*
            Add Incident
        */
    builder
      .addCase(addIncidentToInternThunk.pending, (state: InternState) => {
        state.status = "loading";
      })
      .addCase(
        addIncidentToInternThunk.fulfilled,
        (state: InternState, action: PayloadAction<Intern>) => {
          updateIntern(state, action);
        }
      )
      .addCase(addIncidentToInternThunk.rejected, (state: InternState) => {
        state.status = "error";
      });

    /*
            Add Medical Report
        */
    builder
      .addCase(addMedicalReportToInternThunk.pending, (state: InternState) => {
        state.status = "loading";
      })
      .addCase(
        addMedicalReportToInternThunk.fulfilled,
        (state: InternState, action: PayloadAction<Intern>) => {
          updateIntern(state, action);
        }
      )
      .addCase(addMedicalReportToInternThunk.rejected, (state: InternState) => {
        state.status = "error";
      });

    /*
            Create Intern
        */
    builder
      .addCase(createInternThunk.pending, (state: InternState) => {
        state.status = "loading";
      })
      .addCase(
        createInternThunk.fulfilled,
        (state: InternState, action: PayloadAction<Intern>) => {
          state.interns.unshift(action.payload);
          state.status = "ready";
        }
      )
      .addCase(createInternThunk.rejected, (state: InternState) => {
        state.status = "error";
      });

    /*
            Delete Intern
        */
    builder
      .addCase(deleteInternThunk.pending, (state: InternState) => {
        state.status = "loading";
      })
      .addCase(
        deleteInternThunk.fulfilled,
        (state: InternState, action: PayloadAction<Intern>) => {
          state.status = "ready";
          state.interns = state.interns.filter(
            (intern) => intern.id !== action.payload.id
          );
          if (state.internSelected?.id === action.payload.id) {
            state.internSelected = undefined;
          }
          state.internAction = "none";
        }
      )
      .addCase(deleteInternThunk.rejected, (state: InternState) => {
        state.status = "error";
      });

    /*
            Find Intern By Id
        */
    builder
      .addCase(findInternByIdThunk.pending, (state: InternState) => {
        state.status = "loading";
      })
      .addCase(
        findInternByIdThunk.fulfilled,
        (state: InternState, action: PayloadAction<Intern>) => {
          state.internSelected = action.payload;
          state.status = "ready";
        }
      )
      .addCase(findInternByIdThunk.rejected, (state: InternState) => {
        state.status = "error";
      });

    /*
            Search Interns
       */
    builder
      .addCase(searchInternsThunk.pending, (state: InternState) => {
        state.status = "loading";
      })
      .addCase(
        searchInternsThunk.fulfilled,
        (state: InternState, action: PayloadAction<SearchResponse<Intern>>) => {
          state.interns = action.payload.data;
          state.totalCount = action.payload.totalCount;
        }
      )
      .addCase(searchInternsThunk.rejected, (state: InternState) => {
        state.status = "error";
      });

    /*
            Remove Drug
        */
    builder
      .addCase(removeDrugFromInternThunk.pending, (state: InternState) => {
        state.status = "loading";
      })
      .addCase(
        removeDrugFromInternThunk.fulfilled,
        (state: InternState, action: PayloadAction<Intern>) => {
          updateIntern(state, action);
        }
      )
      .addCase(removeDrugFromInternThunk.rejected, (state: InternState) => {
        state.status = "error";
      });

    /*
            Remove Incident
        */
    builder
      .addCase(removeIncidentFromInternThunk.pending, (state: InternState) => {
        state.status = "loading";
      })
      .addCase(
        removeIncidentFromInternThunk.fulfilled,
        (state: InternState, action: PayloadAction<Intern>) => {
          updateIntern(state, action);
        }
      )
      .addCase(removeIncidentFromInternThunk.rejected, (state: InternState) => {
        state.status = "error";
      });

    /*
            Remove Medical Report
        */
    builder
      .addCase(
        removeMedicalReportFromInternThunk.pending,
        (state: InternState) => {
          state.status = "loading";
        }
      )
      .addCase(
        removeMedicalReportFromInternThunk.fulfilled,
        (state: InternState, action: PayloadAction<Intern>) => {
          updateIntern(state, action);
        }
      )
      .addCase(
        removeMedicalReportFromInternThunk.rejected,
        (state: InternState) => {
          state.status = "error";
        }
      );

    /*
            Update Intern
        */
    builder
      .addCase(updateInternThunk.pending, (state: InternState) => {
        state.status = "loading";
      })
      .addCase(
        updateInternThunk.fulfilled,
        (state: InternState, action: PayloadAction<Intern>) => {
          updateIntern(state, action);
        }
      )
      .addCase(updateInternThunk.rejected, (state: InternState) => {
        state.status = "error";
      });
  },
});

const {
  changeDrugStatus,
  changeInternAction,
  changeFilter,
  changeFilterSkip,
  changeFilterStatus,
  changeFilterTake,
  changeFilterText,
  selectIntern,
} = internSlice.actions;

const getDrugStatus = (state: RootState) => state.intern.drugStatus;
const getInternAction = (state: RootState) => state.intern.internAction;
const getInternFilter = (state: RootState) => state.intern.filter;
const getInternSelected = (state: RootState) => state.intern.internSelected;
const getInterns = (state: RootState) => state.intern.interns;
const getStatus = (state: RootState) => state.intern.status;
const getTotalCount = (state: RootState) => state.intern.totalCount;

export {
  changeDrugStatus,
  changeInternAction,
  changeFilter,
  changeFilterSkip,
  changeFilterStatus,
  changeFilterTake,
  changeFilterText,
  selectIntern,
  getDrugStatus,
  getInternAction,
  getInternFilter,
  getInternSelected,
  getInterns,
  getStatus,
  getTotalCount,
};

export default internSlice.reducer;
