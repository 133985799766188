import { createAsyncThunk } from '@reduxjs/toolkit';
import { Employee } from '../domain/entities/Employee';
import { CreateEmployeeDto, UpdateEmployeeDto, EmployeeFilter, AddPayrollDto, RemovePayrollDto } from './EmployeeDto';
import { EmployeeService } from './EmployeeService';
import { SearchResponse } from '../../commons/Types';

export const addPayrollToEmployeeThunk = createAsyncThunk(
    'employee/add_payroll',
    async (addPayroll: AddPayrollDto): Promise<Employee> => 
        EmployeeService.addPayrollToEmployeeAsync(addPayroll)
)

export const createEmployeeThunk = createAsyncThunk(
    'employee/create',
    async (createEmployee: CreateEmployeeDto): Promise<Employee> =>
        EmployeeService.createEmployeeAsync(createEmployee)
);

export const deleteEmployeeThunk = createAsyncThunk(
    'employee/delete',
    async (employeeId: string) => EmployeeService.deleteEmployeeAsync(employeeId)
);

export const removePayrollToEmployeeThunk = createAsyncThunk(
    'employee/remove_payroll',
    async (removePayroll: RemovePayrollDto): Promise<Employee> => 
        EmployeeService.removePayrollFromEmployeeAsync(removePayroll)
)

export const searchEmployeesThunk = createAsyncThunk(
    'employee/search',
    async (filters: EmployeeFilter): Promise<SearchResponse<Employee>> => EmployeeService.searchEmployeesAsync(filters)
);

export const updateEmployeeThunk = createAsyncThunk(
    'employee/update',
    async (updateEmployee: UpdateEmployeeDto): Promise<Employee> =>
        EmployeeService.updateEmployeeAsync(updateEmployee)
);

export const findEmployeeByIdThunk = createAsyncThunk(
    'employee/find_by_id',
    async (employeeId: string): Promise<Employee> => EmployeeService.findEmployeeByIdAsync(employeeId)
);