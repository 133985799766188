import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Action, StateStatus } from "src/features/commons/Entities";
import { RootState } from "../../../config/store";
import { Center } from "../domain/entities/Center";
import { createCenterThunk, deleteCenterThunk, findCenterByIdThunk, findCenterBySlugThunk, searchCentersThunk, updateCenterThunk } from './CenterThunk';

interface CenterState {
    centerAction: Action;
    centers: Center[];
    centerSelected?: Center;
    status: StateStatus;
}

export const initialState: CenterState = {
    centerAction: 'none',
    centers: [],
    centerSelected: undefined,
    status: 'ready'
}

const centerSlice = createSlice({
    name: 'centers',
    initialState,
    reducers: {
        changeCenterAction: (state: CenterState, action: PayloadAction<Action>) => {
            state.centerAction = action.payload;
        },
        selectCenter: (state: CenterState, action: PayloadAction<Center | undefined>) => {
            state.centerSelected = action.payload;
        }
    },
    extraReducers: (builder: ActionReducerMapBuilder<CenterState>) => {
        /*
            Create Center
        */
        builder.addCase(createCenterThunk.pending, (state: CenterState) => {
            state.status = 'loading';
        }).addCase(createCenterThunk.fulfilled, (state: CenterState, action: PayloadAction<Center>) => {
            state.centers.unshift(action.payload);
            state.centerSelected = undefined;
            state.status = 'ready';
        }).addCase(createCenterThunk.rejected, (state: CenterState) => {
            state.status = 'error';
        });

        /*
            Delete Center
        */
        builder.addCase(deleteCenterThunk.pending, (state: CenterState) => {
            state.status = 'loading';
        }).addCase(deleteCenterThunk.fulfilled, (state: CenterState, action: PayloadAction<Center>) => {
            state.status = 'ready';
            state.centers = state.centers.filter(center => center.id !== action.payload.id);
            if (state.centerSelected?.id === action.payload.id) {
                state.centerSelected = undefined
            }
            state.centerAction = 'none';
        }).addCase(deleteCenterThunk.rejected, (state: CenterState) => {
            state.status = 'error';
        });

        /*
            Find Center By Id
        */
        builder.addCase(findCenterByIdThunk.pending, (state: CenterState) => {
            state.status = 'loading';
        }).addCase(findCenterByIdThunk.fulfilled, (state: CenterState, action: PayloadAction<Center>) => {
            localStorage.setItem('center_id', action.payload.id);
            state.centerSelected = action.payload;
            state.status = 'ready';
        }).addCase(findCenterByIdThunk.rejected, (state: CenterState) => {
            state.status = 'error';
        });


        /*
            Find Center By Slug
        */
        builder.addCase(findCenterBySlugThunk.pending, (state: CenterState) => {
            state.status = 'loading';
        }).addCase(findCenterBySlugThunk.fulfilled, (state: CenterState, action: PayloadAction<Center>) => {
            localStorage.setItem('center_id', action.payload.id);
            state.centerSelected = action.payload;
            state.status = 'ready';
        }).addCase(findCenterBySlugThunk.rejected, (state: CenterState) => {
            state.status = 'error';
        });

        /*
             Search Centers
        */
        builder.addCase(searchCentersThunk.pending, (state: CenterState) => {
            state.status = 'loading';
        }).addCase(searchCentersThunk.fulfilled, (state: CenterState, action: PayloadAction<Center[]>) => {
            state.centers = action.payload;
            state.status = 'ready';
        }).addCase(searchCentersThunk.rejected, (state: CenterState) => {
            state.status = 'error';
        });

        /*
            Update Center
        */
        builder.addCase(updateCenterThunk.pending, (state: CenterState) => {
            state.status = 'loading';
        }).addCase(updateCenterThunk.fulfilled, (state: CenterState, action: PayloadAction<Center>) => {
            state.centers = state.centers.map(center => center.id === action.payload.id ? action.payload : center);
            if (state.centerSelected?.id == action.payload.id) {
                state.centerSelected = action.payload;
            }
            state.status = 'ready';
        }).addCase(updateCenterThunk.rejected, (state: CenterState) => {
            state.status = 'error';
        });
    }
});

const { changeCenterAction, selectCenter } = centerSlice.actions;

const getCenterAction = (state: RootState) => state.center.centerAction;
const getCenters = (state: RootState) => state.center.centers;
const getCenterSelected = (state: RootState) => state.center.centerSelected;
const getStatus = (state: RootState) => state.center.status;

export {
    changeCenterAction,
    selectCenter,

    getCenterAction,
    getCenters,
    getCenterSelected,
    getStatus
}

export default centerSlice.reducer;