import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCPbUd75rNqvm_U6L7GqbnZ4CXDcWaVSvc",
  authDomain: "residencias-fc0fc.firebaseapp.com",
  projectId: "residencias-fc0fc",
  storageBucket: "residencias-fc0fc.appspot.com",
  messagingSenderId: "328976724977",
  appId: "1:328976724977:web:52a5c352620501a8fbca50",
  measurementId: "G-XW8LXBJD4X"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

getAnalytics(firebase.apps[0]);


export default firebase;