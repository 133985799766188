export class InternGender {

    static readonly Male = new InternGender('male');
    
    static readonly Female = new InternGender('female');

    constructor(public readonly value: string) { }

    static getValues(): InternGender[] {
        return [this.Male, this.Female];
    }
    
    static fromString(string: string): InternGender {
        const value = this.getValues().find(enumValue => enumValue.value === string);
        if (value) {
            return value;
        }

        throw new RangeError(
            `Illegal argument passed to fromString(): string does not correspond to any instance of the enum ${
                (this as any).prototype.constructor.name
            }`);
    }

    static getStringValues(): string[] {
        return [this.Male.value, this.Female.value];
    }
}