import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppNavigation from './app/navigation';
import AppInit from './app/components/AppInit';
import 'src/i18n/i18n';
import { findCenterByIdThunk, findCenterBySlugThunk } from './features/center/data/CenterThunk';
import { useAppDispatch, useAppSelector } from './config/hooks';
import { useEffect } from 'react';
import { getCenterSelected } from './features/center/data/CenterSlice';
import { Center } from './features/center/domain/entities/Center';



function App() {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const center: Center = useAppSelector(getCenterSelected);

  localStorage.setItem("userId", auth?.user?.id)

  useEffect(() => {
    const subdomain: string = window.location.host.split('.')[0];
    if (!subdomain.includes('localhost')) {
      dispatch(findCenterBySlugThunk(subdomain));
    } else {
      dispatch(findCenterByIdThunk(process.env.REACT_APP_CENTER_ID));
    }
  }, []);


  if (!center || !localStorage.getItem('center_id')) {
    return <></>;
  }

  return (
    <ThemeProvider centerTheme={center.theme || 'GreyGooseTheme'}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {auth.isInitialized ? <AppNavigation /> : <AppInit />}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;