import { RelativeFilter, CreateRelativeDto, UpdateRelativeDto } from './RelativeDto';
import { Relative } from '../domain/entities/Relative';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'src/config/http';
export abstract class RelativeService {

    public static async createRelativeAsync(createRelative: CreateRelativeDto): Promise<Relative> {
        return Relative.fromJson(await postRequest('/relatives', createRelative));
    }

    public static async deleteRelativeAsync(relativeId: string): Promise<Relative> {
        return Relative.fromJson(await deleteRequest(`/relatives/${relativeId}`));
    }

    public static async findRelativeByIdAsync(relativeId: string): Promise<Relative> {
        return Relative.fromJson(await getRequest(`/relatives/${relativeId}`));
    }

    public static async searchRelativesAsync(filters: RelativeFilter): Promise<Relative[]> {
        return Relative.fromJsonArray(await getRequest('/relatives', JSON.parse(JSON.stringify(filters))));
    }

    public static async searchRelativesByInternIdAsync(internId: string): Promise<Relative[]> {
        return Relative.fromJsonArray(await getRequest(`/relatives?intern_id=${internId}&skip=0&take=20`));
    }

    public static async updateRelativeAsync(updateRelative: UpdateRelativeDto): Promise<Relative> {
        return Relative.fromJson(await patchRequest('/relatives', updateRelative));
    }
}