import { NotArrayError } from '../../../../app/errors/NotArrayError';
export class Drug {
    constructor(
        readonly id: string,
        readonly breakfast: number,
        readonly dinner: number,
        readonly lunch: number,
        readonly name: string,
        readonly midMorning: number,
        readonly snack: number,
        public initDate?: Date,
        public endDate?: Date,
        public notes?: string
    ) { }

    public getTomasString(): string {
        return `${this.breakfast} - ${this.midMorning} - ${this.lunch} - ${this.snack} - ${this.dinner}`;
    }

    public static fromJson(json: any): Drug {
        return new Drug(
            json['id'],
            json['breakfast'],
            json['dinner'],
            json['lunch'],
            json['name'],
            json['midMorning'],
            json['snack'],
            json['initDate'] ? new Date(json['initDate']) : undefined,
            json['endDate'] ? new Date(json['endDate']) : undefined,
            json['notes']
        );
    }

    public static fromJsonArray(jsonArray: any): Drug[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}