import type { ReactNode } from 'react';

import ActivitiesIcon from '@mui/icons-material/SportsGymnasticsTwoTone';
import EmployeeIcon from '@mui/icons-material/BadgeTwoTone';
import InternIcon from '@mui/icons-material/ElderlyTwoTone';
import MenuIcon from '@mui/icons-material/RestaurantMenuTwoTone';
import { NavigationRoutes } from '../../../../navigation/NavigationRoutes';
import LoginIcon from '@mui/icons-material/Login';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'General',
    items: [
      {
        icon: EmployeeIcon,
        name: 'Workers.Title',
        link: NavigationRoutes.Workers
      },
      {
        icon: InternIcon,
        name: 'Interns.Title',
        link: NavigationRoutes.Interns
      },
      {
        icon: MenuIcon,
        name: 'Menus.Title',
        link: NavigationRoutes.Menus
      },
      {
        icon: ActivitiesIcon,
        name: 'Activities.Title',
        link: NavigationRoutes.Activities
      },
      {
        icon: LoginIcon,
        name: "Accesos",
        link: NavigationRoutes.Logs
      },
    ]
  }
];

export default menuItems;
