import { CenterFilter, CreateCenterDto, UpdateCenterDto } from './CenterDto';
import { Center } from '../domain/entities/Center';
import { deleteRequest, getRequest, patchRequest, postRequest } from 'src/config/http';
export abstract class CenterService {

    public static async createCenterAsync(createCenter: CreateCenterDto): Promise<Center> {
        return Center.fromJson(await postRequest('/centers', createCenter));
    }

    public static async deleteCenterAsync(centerId: string): Promise<Center> {
        return Center.fromJson(await deleteRequest(`/centers/${centerId}`));
    }

    public static async findCenterByIdAsync(centerId: string): Promise<Center> {
        return Center.fromJson(await getRequest(`/centers/${centerId}`));
    }

    public static async findCenterBySlugAsync(slug: string): Promise<Center> {
        return Center.fromJson(await getRequest(`/center_by_slug/${slug}`));
    }

    public static async searchCentersAsync(filters: CenterFilter): Promise<Center[]> {
        return Center.fromJsonArray(await getRequest('/centers', JSON.parse(JSON.stringify(filters))));
    }

    public static async updateCenterAsync(updateCenter: UpdateCenterDto): Promise<Center> {
        return Center.fromJson(await patchRequest('/centers', updateCenter));
    }
}