import { Status } from 'src/features/commons/Status';
import { LoginEntity, User } from '../../../commons/Entities';
import { NotArrayError } from '../../../../app/errors/NotArrayError';

export class Relative implements LoginEntity {
    
    toLoginUser(): User {
        return {
            id: this.id,
            avatar: 'noavataravailable',
            email: this.email ?? 'noemail@wisecoders.io',
            name: this.name,
            role: 'relative',
            status: this.status.value
        }
    }

    constructor(
        readonly id: string,
        public internId: string,
        public name: string,
        public phone: string,
        public status: Status,

        public email?: string,
        public surname?: string
    ) {}

    public getFullName(): string {
        if (this.surname) {
            return `${this.name} ${this.surname}`;
        }

        return this.name;
    }

    public getInitials(): string {
        if (this.surname) {
            return `${this.name.charAt(0).toUpperCase()}${this.surname.charAt(0).toUpperCase()}`;
        }

        return `${this.name.charAt(0).toUpperCase()}${this.name.charAt(1).toUpperCase()}`;
    }

    public static fromJson(json: any): Relative {
        return new Relative(
            json['id'],
            json['internId'],
            json['name'],
            json['phone'],
            Status.fromString(json['status']),
            json['email'],
            json['surname'],
        );
    }

    public static fromJsonArray(jsonArray: any): Relative[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}