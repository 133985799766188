import { Status } from "src/features/commons/Status";
import { Group } from "./Group";
import { NotArrayError } from '../../../../app/errors/NotArrayError';
import { Turn } from "./Turn";

export class Activity {
    constructor(
        readonly id: string,
        readonly centerId: string,
        public groups: Group[],
        public name: string,
        public status: Status,
        public notes?: string
    ) { }

    public static fromJson(json: any): Activity {
        return new Activity(
            json['id'],
            json['centerId'],
            Group.fromJsonArray(json['groups']),
            json['name'],
            Status.fromString(json['status']),
            json['notes'],
        );
    }

    public static fromJsonArray(jsonArray: any): Activity[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }

    public findGroupForTurn = (turn: Turn) => {
        return this.groups.find((group) => {
            return group.turns.find((groupTurn) => groupTurn.id === turn.id) !== undefined
        })
    }
}