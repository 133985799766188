import useAuth from 'src/hooks/useAuth';

import {
  Avatar,
  Box,
  Typography,
  useTheme
} from '@mui/material';

function SidebarTopSection() {
  const theme = useTheme();

  const { user, logout } = useAuth();

  return (
    <Box
      sx={{
        textAlign: 'center',
        mx: 2,
        mt: 3,
        pt: 1,
        position: 'relative'
      }}
    >
      <Avatar
        sx={{
          width: 68,
          height: 68,
          mb: 2,
          mx: 'auto',
          bgcolor: 'white',
          color: theme.colors.primary.dark
        }}
        alt={user.name}
        src={user.avatar}
      />

      <Typography
        variant="h4"
        sx={{
          color: `${theme.colors.alpha.trueWhite[100]}`
        }}
      >
        {user.name}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          color: `${theme.colors.alpha.trueWhite[70]}`
        }}
      >
        {user.email}
      </Typography> 
    </Box>
  );
}

export default SidebarTopSection;
