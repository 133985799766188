import { Center } from "src/features/center/domain/entities/Center";
import { Status } from "src/features/commons/Status";
import { EmployeeRole } from "./EmployeeRole";
import { Payroll } from "./Payroll";
import { NotArrayError } from '../../../../app/errors/NotArrayError';
import { LoginEntity, User } from '../../../commons/Entities';

export class Employee implements LoginEntity {
    constructor(
        readonly id: string,
        readonly center: Center,
        public email: string,
        public name: string,
        public phone: string,
        public payrolls: Payroll[],
        public role: EmployeeRole,
        public status: Status,
        public surname: string
    ) { }

    public getFullName(): string {
        if (this.surname) {
            return `${this.name} ${this.surname}`;
        }

        return this.name;
    }

    public getInitials(): string {
        if (this.surname) {
            return `${this.name.charAt(0).toUpperCase()}${this.surname.charAt(0).toUpperCase()}`;
        }

        return `${this.name.charAt(0).toUpperCase()}${this.name.charAt(1).toUpperCase()}`;
    }

    toLoginUser(): User {
        return {
            id: this.id,
            avatar: 'noavataravailable',
            name: this.name,
            email: this.email,
            role: 'worker',
            status: this.status.value
        };
    }

    public static fromJson(json: any): Employee {
        return new Employee(
            json['id'],
            Center.fromJson(json['center']),
            json['email'],
            json['name'],
            json['phone'],
            Payroll.fromJsonArray(json['payrolls']),
            EmployeeRole.fromString(json['role']),
            Status.fromString(json['status']),
            json['surname'],
        );
    }

    public static fromJsonArray(jsonArray: any): Employee[] {
        if (jsonArray instanceof Array) {
            return jsonArray.map(json => this.fromJson(json));
        }

        throw new NotArrayError();
    }
}